import { Ng2ImgMaxService } from 'ng2-img-max';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import {  debounceTime,  map, tap } from 'rxjs/operators';
import { StudentService } from 'src/app/_services/student.service';
import { Student } from 'src/app/model/student';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SchoolService } from 'src/app/_services/school.service';
declare var $: any;
@Component({
  selector: 'app-camera-student',
  templateUrl: './camera-student.component.html',
  styleUrls: ['./camera-student.component.css']
})
export class CameraStudentComponent implements OnInit {
  @ViewChild('studentAddForm') studentAddForm: HTMLFormElement;
  @ViewChild('teacherUpdateForm') teacherUpdateForm: HTMLFormElement;
  isLoading = false;

  cameras = [] // danh sách camera
  cameraSelected = -1;
  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  // countteacher = 0;

  // scan code
  teacherCodeType = 'mts';
  codeScan = "";
  teacherCodeTemp = "";

   //for checkbox
   checkboxs = [];
   checkboxAllButton = false;

    // for arrange



  //for data
  students = [];
  students_backup: any
  categorys: any
  facultys: any
  classs: any
  filter = {
    name: "",
    khoi: "",
    class_code: "",
    gender: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }
  
 
  // for add
  student = new Student();
  imageUrl: any = environment.student_avatar;
  // for update
  studentSelected: any;
  isDeleted: boolean;
  studentSelected_imageUrl: any = environment.student_avatar;
  studentSelected_takeaphoto_imageUrl: any = environment.take_a_photo_avatar;
  fileTakeAPhotoImg: File;

  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  constructor(
    public appService: AppService,
    public studentService: StudentService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    private ng2ImgMax: Ng2ImgMaxService,
    private utility: UtilityService,
    private schoolService: SchoolService,
  ) {
    this.appService.page_title = "Đăng ký ảnh điểm danh";
    this.sorter.sortStr = this.appService.sort_students[0].code;
    this.studentSelected = this.student;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_hoc_sinh);
  }




  ngOnInit() {
    // this.loadStudent();
    // this.loadFaculty();
    // this.loadClasss();
    this.getListDevice();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  selectCamera(i, item){
    // this.cameraSelected = item;
    this.cameraSelected = i;
    this.filter.class_code = item.deviceName;
    this.loadStudent();

  }
  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadStudent();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadStudent();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadStudent();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadStudent();
    }
  };


  loadStudent() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.checkboxs = [];
      this.checkboxAllButton = false;
      this.studentService.loadStudent(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          console.log(response);
          if (response) {
            this.students = response.data;
            this.students_backup = response.data;
            this.checkboxs = new Array(this.students.length);
            // this.appService.students = response.data;
            // this.appService.students_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.students.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
            
          }
        },
        error => {

        })
    }
  }




  // async loadClasss() {
  //   let filter = {
  //     khoi: this.filter.khoi,
  //     type: environment.cateType_class
  //   };
  //   this.categoryeduService.loadAllCategory(filter).subscribe(
  //     async response => {
  //       // this.isLoading.dismiss();
  //       console.log(response);
  //       if (response) {
  //         this.classs = response;
  //         // $('#category').selectpicker();
  //       }
  //     },
  //     error => {
  //       // this.isLoading.dismiss();
  //       console.error(error);
  //     })
  // }






  popupUpdate(item) {
    this.studentSelected = item;
    if(this.studentSelected.image_url != ""){
      this.studentSelected_imageUrl = this.appService.getUrlImage(this.studentSelected.image_url);
    }else{
      this.studentSelected_imageUrl = environment.student_avatar;
    }

    this.studentSelected_takeaphoto_imageUrl = environment.take_a_photo_avatar;
    this.fileTakeAPhotoImg = null;

    $("#update-student-modal").modal('show');
  }

  category_khoi_change(event) {
    // this.loadClasss();
    this.loadStudent();
  }
  category_change(event) {
    this.loadStudent();
  }
  sort_change(event) {
    this.loadStudent();
  }
  
  

  uploadImageAndRegisterChecIn() {

    if (this.fileTakeAPhotoImg == null || typeof(this.fileTakeAPhotoImg) == "undefined") {
      this.toastr.warning("Thầy cô chưa chọn ảnh");
      return;
    } else {
      console.log("2222");
    }


    let param = {
      aliasID : this.studentSelected.code, // mã học sinh là mã faceID
      name: this.studentSelected.full_name,
      placeID: this.appService.currentUser.hanet_placeID, // mã địa điểm của trường, lấy trên server
      title: 'Học Sinh',
    }

    this.isLoading = true;
    this.ioService.uploadImageAndRegisterChecIn(this.fileTakeAPhotoImg, param, this.studentSelected.checkin_status).subscribe(
      async response => {
        this.isLoading = false;
        console.log('Đăng ký mới');
        if(response.returnCode == -9007){
          this.toastr.warning("Hình ảnh học sinh đã được đăng ký",'Thầy/Cô thử lại ảnh khác rõ hơn',{
            timeOut: 7000,
          });
        }else if(response.returnCode == -9005) {
          this.toastr.warning("Học sinh đã được đăng ký",'Hoặc Thầy/Cô chọn cập nhật ảnh',{
            timeOut: 7000,
          });
          // cập nhật là học sinh này đã đăng ký ảnh.
          this.updateStudentCheckin_status(2);

        }else if(response.returnCode == -9006) {
          this.toastr.warning("Vui lòng đảm bảo hình ảnh rõ nét, chỉ có 1 người, đầy đủ mắt, mũi, miệng, nhìn thẳng vào máy ảnh, không đội mũ, đeo khẩu trang",'Hình ảnh không hợp lệ',{
            timeOut: 7000,
          });
        }else if(response.returnCode == 1){ // đăng ký hoặc cập nhật
          this.toastr.success("Đăng ký thành công",'Đăng ký ảnh điểm danh thành công');
          // cập nhật là học sinh này đã đăng ký ảnh.
          this.updateStudentCheckin_status(2);
        }
        console.log(response);
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }

  // closePopup(){
  //   console.log("closePopup");
  //   $("#add-student-modal").modal('hide');
  // }
  updateStudentCheckin_status(status) {
    this.studentSelected.checkin_type_status = status; // đã đăng ký ảnh

    this.studentSelected.birthday = ''; // đặt '' để không phải cập nhật ngày sinh

    this.studentService.update(this.studentSelected).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code == "ok") {
            // this.toastr.success("", "Cập nhật học sinh thành công");
            $("#update-student-modal").modal('hide');
            // this.loadStudent();
          }
        },
        error => {
          this.isLoading = false;
          console.error("error:");
          console.error(error);
        })
  }

  getListDevice() {
    let data = {
     
    };
    this.schoolService.getListDevice(data).subscribe(
      response => {
        this.cameras = response.data
        console.log(this.cameras);
      },
      error => {
        this.toastr.error("Liên hệ quản trị hệ thống để được hỗ trợ", "");
        console.error(error);
      });

  }

  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }

  popup_teacher_export() {
    // let filter = {};
    this.studentService.exportStudent(this.filter);
  }
  teacherCodeTypeClick(type) {
    this.teacherCodeType = type;
    if (this.teacherCodeType == "mts") {
      this.student.code = environment.STUDENT_CODE_PREFIX + Date.now() ;
    } else if (this.teacherCodeType == "tn" || this.teacherCodeType == "qmv") {
      this.student.code = "";
    }
  }
  focusOutCheckCode() {

    let filter = {
      code: this.student.code,
    }
    console.log(filter);
    this.studentService.checkCode(filter).subscribe(
      response => {
        if (response) {
          if(response.code == 0){
            this.studentAddForm.controls.code.setErrors("Đã tồn tại code");
            this.toastr.error("Đã tồn tại code","Lỗi");
          }
        }
        console.log(response);
      },
      error => {
        console.error(error);
      })
  }

  async takeaphotoChange($event): Promise<void> {
    this.fileTakeAPhotoImg = $event.target.files[0];
    if (this.fileTakeAPhotoImg == null) {
      return;
    }
    console.log('originalFile instanceof Blob', this.fileTakeAPhotoImg instanceof Blob); // true
    console.log(`originalFile size 1 ${this.fileTakeAPhotoImg.size / 1024} KB`);
    try {
      this.ng2ImgMax.resizeImage(this.fileTakeAPhotoImg, 1280, 738).subscribe(result => {
        this.fileTakeAPhotoImg = new File([result], result.name);
        // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

        var reader = new FileReader();
        reader.readAsDataURL(this.fileTakeAPhotoImg);
        reader.onload = (_event) => {
          this.studentSelected_takeaphoto_imageUrl = reader.result;
        }
      },
        error => {
          console.log('error', error);
        })
    } catch (error) {
      console.log(error);
    }
  }



}



