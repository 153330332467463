
import { ProductService } from './../../../_services/product.service';
import { Membership } from './../../../model/membership';
import { UserService } from './../../../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ScriptLoaderService } from './../../../_services/script-loader.service';
import { environment } from './../../../../environments/environment';
import { UtilityService } from './../../../_services/utility.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../_services/app.service';
import { SaleService } from '../../../_services/sale.service';
import { CartObj } from '../../../model/cart-obj';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckinClassDetailComponent } from '../../checkin-report/checkin-class-detail/checkin-class-detail.component';
import { CheckinUnknownDetailComponent } from '../../checkin-unknown/checkin-unknown-detail/checkin-unknown-detail.component';
import { TeacherService } from 'src/app/_services/teacher.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
declare var $: any;

@Component({
  selector: 'app-schedules-daily',
  templateUrl: './schedules-daily.component.html',
  styleUrls: ['./schedules-daily.component.css']
})
export class SchedulesDailyComponent implements OnInit {
  isLoading = false;

  facultys: any // danh sách tổ bộ môn
  teacher_list: any // danh sách tổ bộ môn
  

  //for data

  classs: any
  filter = {
    code: "",
    khoi_code: "",
    class_code: "",
    faculty_code : ''
  }
  dateRange = [];
  curentMonday: Date;

  dataList = [];
  khoiSelected = -1;

  ///////////////
  sales = [];

  day_num = 7;
  salesTitleByday_result = [];
  sum_prices = 0;

  // for detail
  saleSelectedItem = new CartObj();
  cartDetail: any;
  //status
  sale_status = environment.SALE_STATUS_ALL;

  //member nvbh
  memberships = [];
  memberships_backup = [];
  membership = new Membership();
  nvbhSelected: any;


  //for dateTime
  hoveredDate: NgbDate | null = null;
  fromDate: Date | null;
  toDate: NgbDate | null;
  fromDateStr: string;
  selectedDateStr: string;
  dateListSelected: any;
  type_range = 'day';



  //for search
  searching = false;
  code_filter = "";
  //for sorter
  sorter = {
    sortStr: this.appService.sort_reports[0].code,
  }
  //
  cancel_reason = ""
  constructor(
    private teacherService: TeacherService,
    public categoryeduService: CategoryEduService,
    public utilityService: UtilityService,
    public appService: AppService,
    public saleService: SaleService,
    private toastr: ToastrService,
    public userService: UserService,
    public productService: ProductService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public dialog: MatDialog,
    config: NgbModalConfig,
  ) {
    this.appService.page_title = "Lịch ngày";
    config.backdrop = 'static';
    config.keyboard = false;

    this.fromDate = new Date();
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate);

    // this.dateListSelected = this.utilityService.get7days(this.dateSelected);
  }
  ngOnInit() {
    this.loadTeacher();
    this.loadFaculty();
    this.loadTeachingAssignmentDaily();
    this.loadCurrenMonday(new Date);
  }


  onValChange(item) {
    this.type_range = item;
    this.loadTeachingAssignmentDaily();
    console.log(this.type_range);
  }
  category_change(event) {
    this.loadTeachingAssignmentDaily();
  }
  loadFaculty() {
    let filter = {
      type: environment.cateType_faculty
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  loadTeacher() {
    let pagingObj = {
      viewNumsObj: 200,
      pageObj: 1
    };
    let filter = {
      
    };
    let sorter = {
      sortStr: "name ASC",
    }
    this.teacherService.loadTeacher(filter, sorter, pagingObj).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.teacher_list = response.data;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  confirmDownloadExcel() {
    let data = {
      title: 'Tải lịch dạy',
      msg: 'Xác nhận tải.',
      ok : 'Tải xuống'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        console.log(this.type_range ,this.selectedDateStr, this.filter.code);
        this.teacherService.downloadTeachingAssignmentDaily(this.type_range ,this.selectedDateStr, this.filter.code);
      }
    });
  }
  loadTeachingAssignmentDaily() {

    this.isLoading = true;
    this.teacherService.loadTeachingAssignment_daily(this.type_range ,this.selectedDateStr, this.filter).subscribe(
      async response => {
        this.isLoading = false;
        if (response) {
          this.dataList = [];
          console.log(response);
          let temp = ''
          response.forEach(item => {
            if (temp != item.date_day) {
              item.note = '';
              temp = item.date_day;
              // thêm 1 dòng trống title ngày
              this.dataList.push({
                  "date_day": item.date_day.toUpperCase(),
                  "date_item": item.date_item
              });
          } else {
              item.note = '';
          }
          this.dataList.push(item);
          });
        }
      },
      error => {
        this.isLoading = false;
        console.error(error);
      })
  }


  popup_export_excel() {

  }
  onDateSelect($event) {
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
      this.loadCurrenMonday(this.fromDate);
    this.selectDate(this.selectedDateStr);
  }

  selectDate(dateStr) {
    // console.log(dateStr);
    // this.fromDate = dateStr;
    // console.log(this.fromDate);
    this.selectedDateStr = dateStr;
    this.loadTeachingAssignmentDaily();
    console.log(this.selectedDateStr);
  }


  loadCurrenMonday(curr: Date) {
    // var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    // var last = first + 6; // last day is the first day + 6
    if (curr.getDate() == first) first = first - 6; // nếu ngày chọn là ngày chủ nhật
    else first++;

    this.curentMonday = new Date(curr.setDate(first));
    this.loadDateRange(this.curentMonday, 7);
    // var temp = this.curentMonday;
    // var sunday = new Date(curr.setDate(last+1)).toUTCString();
  }
  loadDateRange(date, days) {
    this.dateRange = [];
    for (let i = 0; i < days; i++) {
      var temp = new Date(date);
      temp.setDate(temp.getDate() + i);
      this.dateRange.push(
        {
          dayOfWeek: this.utilityService.getDayOfWeekFromDate(temp),
          ddmm: this.utilityService.getDDMMStrFromDate(temp),
          ddmmyyy: this.utilityService.getFullDateStrFromDate(temp),
        }
      );
    }
    console.log(this.dateRange);
  }

  selectPerson(item) {
    const dialogRef = this.dialog.open(CheckinUnknownDetailComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      // disableClose : true,
      // width: '90%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: item
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        console.log("result", result);

      }
    });
  }

  ngAfterViewInit() {
    // this.loadSales(this.selectedDateStr);
  }


  day_num_change() {
    // this.getSalesBydays();
  }
  sale_status_change() {
    // this.getSalesBydays();
    // this.loadSales(this.selectedDateStr);
  }




}

