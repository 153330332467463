import { Ng2ImgMaxService } from 'ng2-img-max';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../_services/app.service';
import { CategoryService } from '../../_services/category.service';
import { ToastrService } from 'ngx-toastr';
import { HostListener } from '@angular/core';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { TeacherService } from 'src/app/_services/teacher.service';
import { Teacher } from 'src/app/model/teacher';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { retry } from 'rxjs-compat/operator/retry';
declare var $: any;
@Component({
  selector: 'app-teaching-assignment',
  templateUrl: './teaching-assignment.component.html',
  styleUrls: ['./teaching-assignment.component.css']
})
export class TeachingAssignmentComponent implements OnInit {
  @ViewChild('teacherAddForm') teacherAddForm: HTMLFormElement;
  @ViewChild('teacherUpdateForm') teacherUpdateForm: HTMLFormElement;
  isLoading = false;
  isHighlight = false;
  // tempForIsHighlight = []; // chỉ để cho isHightLine
  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  countteacher = 0;

  // scan code
  teacherCodeType = 'mts';
  codeScan = "";
  teacherCodeTemp = "";

  //for color
  // colorList = [{ code: "black" }, { code: "white" }, { code: "gray" }, { code: "red" }, { code: "green" }, { code: "blue" }, { code: "yellow" }];



  //for data
  teachers = [];
  teachers_backup: any
  categorys_subject: any
  categorys_class: any
  facultys: any
  filter = {
    name: "",
    positions: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }


  // for add
  teacher = new Teacher("");
  fileteacherImg: File;
  imageUrl: any = environment.teacher_avatar;

  //for paging
  viewNumsObj = 200;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  // for nghiệp vụ //for teaching assignment
  teacherIndexSelected = -1;
  teacherSelected = [];
  subjectIndexSelected = -1;
  subjectSelected = [];
  hockySelectedList = [];
  hockyIIIndexSelected = -1;
  teachingAssignmentData = [];


  constructor(
    public appService: AppService,
    public teacherService: TeacherService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public ioService: IoService,
    private toastr: ToastrService,
    private utility: UtilityService,
  ) {
    this.appService.page_title = "Phân công giảng dạy";
    this.sorter.sortStr = this.appService.sort_teachers[0].code;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_giao_vien);

  }




  ngOnInit() {
    this.loadTeacher();

    this.loadFaculty();
    this.loadCategorySubjects();

  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }

  clearSearch() {
    this.filter.name = '';
    this.loadTeacher();
  }
  // selectClassI(evt, i) {
  //   console.log(evt.target.checked);
  //   this.hockyIIIndexSelected = i;
  // }
  caphoc_change(code) {
    if(this.appService.capSelected == code){
      this.appService.capSelected = '';
    }else{
      this.appService.capSelected = code;
    }
    this.teacherIndexSelected = -1;
    this.subjectIndexSelected = -1;
    this.teacherSelected = [];
    this.hockySelectedList = [];
    this.loadCategorySubjects();
    console.log(code);
  }
  clickTeacher(i) {

    if (this.appService.checkFullPermission() || this.teachers[i].code == this.appService.currentUser.code) {
      this.teacherIndexSelected = i;
      this.subjectIndexSelected = -1;
      this.hockySelectedList = [];
      if (this.teachers[i].teachingAssignment != "")
        this.teacherSelected = this.teachers[i].teachingAssignment;
      else
        this.teacherSelected = [];
    } else {
      this.toastr.warning("", "Thầy cô chỉ có thể phân công cho chính mình");
    }
  }
  hocky_change(item) {
    this.appService.hockySelected = item;
    this.teacherIndexSelected = -1;
    this.subjectIndexSelected = -1;
    this.teacherSelected = [];
    this.hockySelectedList = [];
    this.loadTeacher();
    this.loadCategorySubjects();
  }
  clickSubjects(i) {
    if (this.teacherIndexSelected < 0) {
      this.toastr.warning("", "Vui lòng chọn giáo viên");
    } else {
      if (this.subjectIndexSelected == i) {
        // this.subjectIndexSelected = -1;
      }
      else {
        this.subjectIndexSelected = i;
      }
      // đây là object của teaching assignment
      var classs = {
        teacher_code: this.teachers[this.teacherIndexSelected].code,
        hocky: this.appService.hockySelected,
        subjects_code: this.categorys_subject[i].code,
        subjects_name: this.categorys_subject[i].name,
        class: [] // xóa hoặc thêm
      };

      this.addSubjectsToTeacher(classs);
      this.loadCategoryClassBySubject(this.categorys_subject[i].code);
    }


    this.hockySelectedList = this.teachers[this.teacherIndexSelected].teachingAssignment[this.getIndexInTeaAss(this.subjectIndexSelected)];
  }
  toggleClassSelect(evt) {
    if (this.subjectIndexSelected < 0) {
      evt.preventDefault();
      this.toastr.warning("", "Thầy cô chưa chọn môn");
      return
    }

    this.toggleClassToSubjects(evt.target.checked);
  }
  toggleClassToSubjects(toggle) {
    // tìm index trong teachingAssignment
    var existingSubIndex = this.getIndexInTeaAss(this.subjectIndexSelected);

    // đây là danh sách class của giáo viên, của môn hiện tại
    var subClassTeaAssObj = this.teachers[this.teacherIndexSelected].teachingAssignment[existingSubIndex];

    //kiểm tra toggle
    if (!toggle) { //nếu không chọn thì xoá hết
      subClassTeaAssObj.class = [];
    } else {
      //lặp qua tất cả các class và thêm vào
      this.categorys_class.forEach(item => {
        subClassTeaAssObj.class.push({
          'class_code': item.code,
          'class_name': item.name,
          'actions': true  // xóa hoặc thêm
        });
      });

    }

    console.log(subClassTeaAssObj);

    this.teachers[this.teacherIndexSelected].teachingAssignment[existingSubIndex] = subClassTeaAssObj
  }

  selectClass(evt, i) {

    if (this.subjectIndexSelected < 0) {
      evt.preventDefault();
      this.toastr.warning("", "Thầy cô chưa chọn môn");
    } else {
      if (true) {
        var classs = {
          class_code: this.categorys_class[i].code,
          class_name: this.categorys_class[i].name,
          actions: evt.target.checked  // xóa hoặc thêm
        };
        this.addClassToSubjects(classs);
      } else {

      }
    }

  }

  addSubjectsToTeacher(subjects) {
    if (typeof this.teachers[this.teacherIndexSelected].teachingAssignment == "undefined" || this.teachers[this.teacherIndexSelected].teachingAssignment == "")
      this.teachers[this.teacherIndexSelected].teachingAssignment = [];

    //kiểm tra đã tồn tại chưa và tra về vị trí tồn tại
    var existingIndex = -1;
    for (let i = 0; i < this.teachers[this.teacherIndexSelected].teachingAssignment.length; i++) {
      if (subjects.subjects_code == this.teachers[this.teacherIndexSelected].teachingAssignment[i].subjects_code) {
        existingIndex = i;
        break;
      }
    }
    if (existingIndex == -1)// chưa có
      this.teachers[this.teacherIndexSelected].teachingAssignment.push(subjects);
    else {
      // this.teachers[this.teacherIndexSelected].teachingAssignment.splice(existingIndex, 1);
    }
    this.teacherSelected = this.teachers[this.teacherIndexSelected].teachingAssignment;
    // console.log(this.teachers[this.teacherIndexSelected].teachingAssignment);
  }
  addClassToSubjects(classs) {
    console.log(classs);
    // tìm index trong teachingAssignment
    var existingSubIndex = this.getIndexInTeaAss(this.subjectIndexSelected);

    var subClassTeaAssObj = this.teachers[this.teacherIndexSelected].teachingAssignment[existingSubIndex];
    //kiểm tra đã tồn tại chưa và tra về vị trí tồn tại
    var existingIndex = -1;
    for (let i = 0; i < subClassTeaAssObj.class.length; i++) {
      if (classs.class_code == subClassTeaAssObj.class[i].class_code) {
        existingIndex = i;
        break;
      }
    }
    if (existingIndex == -1)// chưa có thì kiểm tra tiếp để thêm
      // if(classs.actions) // tích chọn thì thêm mới
      subClassTeaAssObj.class.push(classs);
    else {
      //nếu đã tồn tại thì thay hoặc xóa
      if (classs.actions) // tích chọn thì thêm mới
        subClassTeaAssObj.class[existingIndex] = classs;
      else
        subClassTeaAssObj.class.splice(existingIndex, 1);
    }
    this.teachers[this.teacherIndexSelected].teachingAssignment[existingSubIndex] = subClassTeaAssObj
  }


  getIndexInTeaAss(j): number {
    var existingSubIndex = -1;
    if (typeof this.teachers[this.teacherIndexSelected].teachingAssignment != "undefined") {

      for (let i = 0; i < this.teachers[this.teacherIndexSelected].teachingAssignment.length; i++) {
        if (this.teachers[this.teacherIndexSelected].teachingAssignment[i].subjects_code == this.categorys_subject[j].code) {
          existingSubIndex = i;
          break;
        }
      }
    }
    console.log(existingSubIndex);
    return existingSubIndex
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadTeacher();
  }


  updateTeachingAssignment() {

    // if (!(this.appService.checkFullPermission() || this.teachers[this.teacherIndexSelected].code == this.appService.currentUser.code)) {
    //   this.toastr.warning("", "Thầy cô chỉ có thể phân công cho chính mình");
    //   return;
    // }

    console.log(this.teachers);
    var teachingAssignmentObj = [];
    // lặp danh sách giáo viên
    this.teachers.forEach(element => {
      if (typeof element.teachingAssignment != "undefined" && element.teachingAssignment != "") {
        // lặp danh sách lớp
        if (element.teachingAssignment.length > 0) {
          element.teachingAssignment.forEach(subjectsItem => {
            // nếu subjects không có lớp
            if (subjectsItem.class.length > 0) {
              subjectsItem.class.forEach(classItem => {
                var temp = {
                  teacher_code: subjectsItem.teacher_code,
                  hocky: subjectsItem.hocky,
                  subjects_code: subjectsItem.subjects_code,
                  subjects_name: subjectsItem.subjects_name,
                  class_code: classItem.class_code,
                  class_name: classItem.class_name,
                };
                teachingAssignmentObj.push(temp);
              });
            } else {
              var temp = {
                teacher_code: subjectsItem.teacher_code,
                hocky: subjectsItem.hocky,
                subjects_code: subjectsItem.subjects_code,
                subjects_name: subjectsItem.subjects_name,
                class_code: "",
                class_name: "",
              };
              teachingAssignmentObj.push(temp);
            }
          });
        } else {

        }
        // chuyển mảng class thành jsonStr đẻ gửi lên server
        // element.teachingAssignment.classss =  JSON.stringify(element.teachingAssignment.class);
      }
    });
    console.log(teachingAssignmentObj);
    this.isLoading = true;
    this.teacherService.updateTeachingAssignment(teachingAssignmentObj).subscribe(
      async response => {
        this.isLoading = false;
        this.isHighlight = false;
        console.log(response);
        if (response.affected > 0) {
          this.toastr.success("", "Cập nhật phân công giảng dạy thành công");
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error("", "Cập nhật phân công giảng dạy thành công");
        console.error('error', error);
      })

  }

  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadTeacher();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadTeacher();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadTeacher();
    }
  };

  loadCategorySubjects() {

    let pagingObj = {
      viewNumsObj: 100,
      pageObj: 1
    };
    let filter = {
      type: environment.cateType_subjects,
      subject_cap: this.appService.capSelected
    };
    let sorter = {
      sortStr: "od",
    }

    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys_subject = response.data;
          console.log("this.categorys_subject:", this.categorys_subject);
        }
      },
      error => {
        console.error(error);
      })
  }
  loadCategoryClassBySubject(subject_code) {

    let data = {
      subject_code: subject_code,
    }
    this.categorys_class = [];
    this.categoryeduService.loadCategoryClassBySubject(data).subscribe(
      async response => {
        if (response) {
          this.categorys_class = response;
          // console.log(this.categorys_class);
        }
      },
      error => {
        console.error(error);
      })
  }

  onInputChange() {
    this.isHighlight = true;
    // Thực hiện các xử lý khác ở đây nếu cần
  }

  async loadTeacher() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.teacherService.loadTeacher(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          // console.log(response);
          if (response) {
            this.teachers = response.data;
            this.teachers_backup = response.data;
            // console.log(this.teachers);
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.teachers.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;

            this.loadTeachingAssignment();
          }
        },
        error => {

        })
    }
  }
  loadTeachingAssignment() {
    this.teachingAssignmentData = [];
    let filter = { 'hocky': this.appService.hockySelected };
    this.teacherService.loadTeachingAssignment(filter).subscribe(
      async response => {
        // console.log('loadTeachingAssignment',response);
        if (response.length > 0) {

          for (let i = 0; i < response.length; i++) {
            let subjects_class = {
              teacher_code: response[i].teacher_code,
              hocky: response[i].hocky,
              subjects_code: response[i].subjects_code,
              subjects_name: response[i].subjects_name,
              class: [] // xóa hoặc thêm
            };
            // xử lý class
            if (response[i].class_codes != "") {
              var class_codes = response[i].class_codes.split(",");
              var class_names = response[i].class_names.split(",");
              var temps = []
              // lặp qua class_code và dùng index chung cho clas_name
              class_codes.forEach((item, i) => {
                temps.push({
                  class_code: item,
                  class_name: class_names[i],
                  actions: true,
                })
              });
              // gán class
              subjects_class.class = temps;
            }
            this.teachingAssignmentData.push(subjects_class);
          }
          // console.log("this.teachingAssignmentData",this.teachingAssignmentData);
          this.loadTeachingAssignmentToTeacher(this.teachers, this.teachingAssignmentData);
        }
      },
      error => {

      })
  }

  loadTeachingAssignmentToTeacher(teacher, assignment) {
    teacher.forEach((item, i) => {

      item.teachingAssignment = []

      var countSubjects = 0; //
      assignment.forEach((assignmentItem, i) => {
        if (item.code == assignmentItem.teacher_code) {
          countSubjects++; // 
          item.teachingAssignment.push(assignmentItem);
        }
      });

    });

  }

  async loadFaculty() {
    let filter = {
      type: environment.cateType_faculty
    };
    this.categoryeduService.loadAllCategory(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        // console.log(response);
        if (response) {
          this.facultys = response;
          // $('#category').selectpicker();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }







  category_change(event) {
    this.loadTeacher();
  }
  sort_change(event) {
    this.loadTeacher();
  }
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.teachers = this.teachers_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.teachers = this.teachers.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }


  teacherCodeTypeClick(type) {
    this.teacherCodeType = type;
    if (this.teacherCodeType == "mts") {
      this.teacher.code = this.teacherCodeTemp;
    } else if (this.teacherCodeType == "tn" || this.teacherCodeType == "qmv") {
      this.teacher.code = "";
    }
  }

  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.teacher_avatar + ')';
  // }
  //for search
  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        // console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadTeacher();
      }),
      map(term => term === '' ? [] : this.teachers.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    console.log("Tìm kiếm: ", this.filter);
    this.filter.name = "";
  }
}


