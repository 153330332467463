<div class="" role="document">
    <form class="form-horizontal" #studentAddForm="ngForm" id="form-add-student">
        <div class="modal-header">
            <div class="modal-title">Thêm mới học sinh</div>
        </div>
        <div class="modal-body p-3">
            <div class="row">
                <div class="col-sm-9">

                    <mat-form-field class="example-full-width mb-2" appearance="fill">
                        <mat-label>Họ và tên</mat-label>
                        <input matInput [(ngModel)]="student.full_name" placeholder="Họ và tên học sinh" name="name" required maxlength="100">
                    </mat-form-field>

                    <div class="form-group row">
                        <mat-form-field appearance="fill">
                            <mat-label>Mã học sinh</mat-label>
                            <input matInput placeholder="Mã học sinh" type="text" [(ngModel)]="student.code" required minlength="3" maxlength="15" name="code" [readonly]="'mts' === codeType || 'qmv' === codeType" (ngModelChange)="student.code = $event.toUpperCase()" (focusout)="focusOutCheckCode()"
                                (keydown.space)="$event.preventDefault();">
                        </mat-form-field>
                    </div>
                    <div class="form-group row" style="position: relative; height: 20px;">
                        <div style="position: absolute; top: -7px;">
                            <div class="">
                                <label class="radio radio-grey radio-primary radio-inline">
                                <input type="radio" name="b" [checked]="'mts' === codeType"
                                    (click)="teacherCodeTypeClick('mts');">
                                <span class="input-span"></span>Mã tự sinh</label>
                                <label class="radio radio-grey radio-primary radio-inline">
                                <input type="radio" name="b" [checked]="'tn' === codeType"
                                    (click)="teacherCodeTypeClick('tn');">
                                <span class="input-span"></span>Tự nhập</label>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="col  d-flex justify-content-center">

                    <div class="image-upload">
                        <div class="file-input" [style.background-image]="'url('+imageUrl+')'">
                        </div>
                        <input type="file" accept="image/gif, image/jpeg, image/png" id="upload2File" name="upload2File" (change)="avatarChangeListener($event)">
                    </div>

                </div>
            </div>


            <ul class="nav nav-tabs tabs-line">
                <li class="nav-item"><a href="#tab-info-coban" data-toggle="tab" aria-expanded="true" class="nav-link active">Thông tin chung</a></li>
                <!-- <li class="nav-item"><a href="#tab-info-phuhuynh" data-toggle="tab" aria-expanded="true" class="nav-link">Phụ huynh</a></li> -->
                <li class="nav-item"><a href="#tab-info-suckhoe" data-toggle="tab" aria-expanded="true" class="nav-link">Sức khỏe</a></li>
                <li class="nav-item"><a href="#tab-info-khac" data-toggle="tab" aria-expanded="false" class="nav-link">khác</a></li>
            </ul>
            <div class="tab-content">

                <div class="tab-pane fade active show" id="tab-info-coban" aria-expanded="true">
                    <div class="row mb-3">
                        <div class="col">
                            <mat-form-field appearance="fill">
                                <mat-label>Lớp</mat-label>
                                <mat-select [(ngModel)]="student.class_code" name="class_code" required>
                                    <mat-option *ngFor="let item of classs" [value]="item.code">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="col">
                            <mat-form-field appearance="fill">
                                <mat-label>Dân tộc</mat-label>
                                <mat-select [(ngModel)]="student.dan_toc" name="dan_toc">
                                    <mat-option *ngFor="let item of appService.dan_tocs" [value]="item.code">
                                        {{item.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col">


                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Ngày sinh</mat-label>
                                <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="birthday_temp" name="birthday">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker touchUi #picker></mat-datepicker>
                            </mat-form-field>


                        </div>
                        <div class="col">
                            <section class="qbs-radio-cover">
                                <label class="lable">Giới tính</label>
                                <mat-radio-group [(ngModel)]="student.gender" name="gendergender">
                                    <mat-radio-button class="example-margin" [value]="1">Nam</mat-radio-button>
                                    <mat-radio-button class="example-margin" [value]="0">Nữ</mat-radio-button>
                                </mat-radio-group>
                            </section>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label class="col-form-label">Số điện thoại</label>
                                <div class="">
                                    <input [(ngModel)]="student.phone" class="form-control" name="phone" numbersOnly maxlength="10" minlength="10" placeholder="Số điện thoại (dùng để đăng nhập)" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="col-form-label">Email</label>
                                <div class="">
                                    <input [(ngModel)]="student.email" class="form-control" name="email" placeholder="Email" type="text">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Địa chỉ</mat-label>
                        <input matInput [(ngModel)]="student.address" placeholder="Địa chỉ" name="address" maxlength="200">
                    </mat-form-field>


                </div>
                <div class="tab-pane fade " id="tab-info-phuhuynh" aria-expanded="false">
                    Thông tin phụ huynh, vui lòng cập nhật sau
                </div>
                <div class="tab-pane fade text-center" id="tab-info-suckhoe" aria-expanded="false">
                    Thông tin Sức khỏe của học sinh
                </div>
                <div class="tab-pane fade text-center" id="tab-info-khac" aria-expanded="false">
                    Thông tin khác
                </div>
            </div>
        </div>

        <div class="modal-footer justify-content-around bg-primary-50">
            <button class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" [disabled]="isLoading || this.studentAddForm.invalid" (click)="insertStudent()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-plus"></i></span>
                    Thêm mới
                </button>


            <button class="btn btn-primary " (click)="this.dialogRef.close();">Đóng</button>
        </div>
    </form>
</div>