import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinancePrintInvoiceComponent } from '../finance-print-invoice/finance-print-invoice.component';
import { FinanceService } from 'src/app/_services/finance.service';
@Component({
  selector: 'app-finance-qrcode-preview',
  templateUrl: './finance-qrcode-preview.component.html',
  styleUrls: ['./finance-qrcode-preview.component.css']
})
export class FinanceQrcodePreviewComponent implements OnInit {
  isLoading = false;
  paymentMethod = {
      bankInfo: "",
      base64: "",
  }
  constructor(    
    public financeService: FinanceService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FinancePrintInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any) {
        //data có định dạng như sau:
        // data: {
        //   'student' : ,
        //   'finance' : 
        // }
    }

  ngOnInit(): void {
    this.loadData();
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  loadData(){
    let data = {
      student_code : this.data.student.code,
      finance_student_id : this.data.finance.finance_student_id,
      merge_code : this.data.finance.merge_code,
    }
    this.isLoading = true;
    this.financeService.loadQRCode(data).subscribe(
      async response => {
        this.isLoading = false;
        this.paymentMethod = response;


      },
      error => {
        console.error(error);
        this.isLoading = false;
      })
  }
}
