
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';


@Injectable({
  providedIn: 'root'
})
export class IoService {

  constructor(
    public http: HttpClient,
    private appService: AppService
  ) {

  }

  uploadImage(file, param) {
    const formData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('action', "UPLOAD_IMAGE");
    // formData.append('shop_code', this.appService.currentUser.shop_code);
    // formData.append('user_id', this.appService.currentUser.id.toString());
    formData.append('des', param.des);
    formData.append('name', param.name);
    return this.http.post<any>(this.appService.getApiEndpoint('io/uploadImg'), formData).map(data => { return data; });
  }
  uploadImageAndRegisterChecIn(file, param, checkin_status) {

    if (checkin_status == 2) {  // 2 là update
      const formData = new FormData();
      formData.append('token', this.appService.currentUser.access_token);
      formData.append('file[]', file, file.name);
      formData.append('name', param.name);
      formData.append('aliasID', param.aliasID); // code student
      formData.append('placeID', param.placeID); // id camera lớp
      return this.http.post<any>('https://partner.hanet.ai/person/updateByFaceImage', formData).map(data => {
        return data;
      });
    }else {  // 1 đăng ký mới
      const formData = new FormData();
      formData.append('token', this.appService.currentUser.access_token);
      formData.append('name', param.name);
      formData.append('file', file, file.name);
      formData.append('aliasID', param.aliasID); // code student
      formData.append('placeID', param.placeID); // id camera lớp
      formData.append('title', param.title);
      formData.append('type', "0"); // 0 nhân viên ; 1 Khách hàng
      return this.http.post<any>('https://partner.hanet.ai/person/register', formData).map(data => {
        return data;
      });
    }

  }
  uploadAndRegisterFaceId(file, param) {
      const formData = new FormData();
      formData.append('uploadFile', file, file.name);
      formData.append('student_code', param.student_code);
      formData.append('class_code', param.class_code);
      formData.append('name', param.name);
      formData.append('type_user', param.type_user); // phhs hoặc cbgv
      formData.append('checkin_type_status', param.checkin_type_status); // 
      // Hanet
      formData.append('aliasID', param.student_code); // 
      formData.append('title', param.title); // 
      const config = {
        headers: {
          'student_code': param.student_code // Thêm các headers khác nếu cần
        }
      };

      return this.http.post<any>(this.appService.getApiEndpoint('io/uploadImageAndRegisterChecIn'), formData, config).map(data => {
        return data;
      });
      // return this.http.post<any>(this.appService.getApiEndpoint('camera/registerFaceId'), formData).map(data => {
      //   return data;
      // });

  }

}




