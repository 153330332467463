import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/_services/utility.service';
import { IoService } from 'src/app/_services/io.service';
import { Observable } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { StudentService } from 'src/app/_services/student.service';
import { Student } from 'src/app/model/student';
import { CategoryEduService } from 'src/app/_services/category-edu.service';
import { SchoolService } from 'src/app/_services/school.service';
import { MatDialog } from '@angular/material/dialog';
import { ArrangeDataComponent } from 'src/app/components/arrange-data/arrange-data.component';
import { AppService } from 'src/app/_services/app.service';
import { CategoryService } from 'src/app/_services/category.service';
import { environment } from 'src/environments/environment';
import { StudentRegisterImageCameraComponent } from '../../student/student-register-image-camera/student-register-image-camera.component';
import { StudentUpdateComponent } from '../../student/student-update/student-update.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AssessmentService } from 'src/app/_services/assessment.service';
import { DataService } from 'src/app/model/data.service';
import { AssessmentPeriodViewComponent } from '../assessment-period-view/assessment-period-view.component';

declare var $: any;
@Component({
  selector: 'app-assessment-period',
  templateUrl: './assessment-period.component.html',
  styleUrls: ['./assessment-period.component.css']
})
export class AssessmentPeriodComponent implements OnInit {
  isLoading = false;
  isLoading_student = false;
  // class checkbox
  checkboxAllButton = true; // mặc định chọn all
  class_codeSelected: string = '';
  student_selected = new Student();
  
  // for import
  template_file_url = "";
  file: File;
  import_result = {
    result: false,
    success: 0,
    fail: 0,
    msg: ""
  };
  // countteacher = 0;

  //Môn học cho thông báo theo đợt THCS
  subjects = [];
  //danh sách tháng theo scholastic
  monthList = [];

  //for dateTime
  fromDate: Date | null;
  selectedDateStr: string = "";
  dateListSelected: any;



  // cấp học theo lớp
  cap_hoc = ''
  period_code = ''
  period_name = ''

  //for checkbox
  checkboxs_changeClass = [];
  //  checkboxAllButton = false;

  // for action
  action_mode = "";


  //for data
  students = [];
  students_backup: any
  categorys: any
  classs: any
  students_numberAssessmentPeriod = 0
  filter = {
    name: "",
    period_time: "",
    period_code: "",
    khoi_code: "",
    class_code: "",
    gender: "",
    faculty_code: "",
    district_code: "",
    employee_code: "",
    deleted: 0,
    category_code: "",
    category_code_brand: ""
  }


  // // for add
  // student = new Student();
  // fileteacherImg: File;
  // imageUrl: any = environment.student_avatar;
  // // for update
  // studentSelected: any;
  // isDeleted: boolean;
  // studentSelected_imageUrl: any = environment.student_avatar;
  khoicosos = [];
  //for paging
  viewNumsObj = this.appService.viewNums[3].Code;
  curentPage = 1;
  totalItemOnPage: any;
  totalItems: any;
  listPage: any = [];

  sorter = {
    sortStr: "",
  }
  //for search
  searching = false;

  constructor(
    public appService: AppService,
    public dataService: DataService,
    public studentService: StudentService,
    public categoryService: CategoryService,
    public categoryeduService: CategoryEduService,
    public schoolService: SchoolService,
    public ioService: IoService,
    private toastr: ToastrService,
    private assessmentService: AssessmentService,
    private utilityService: UtilityService,
    public dialog: MatDialog,
  ) {
    this.appService.page_title = "Sổ điểm theo đợt";
    this.sorter.sortStr = this.appService.sort_students[0].code;
    // this.studentSelected = this.student;
    this.template_file_url = this.appService.getApiEndpoint(environment.mau_nhap_hoc_sinh);

    this.selectedDateStr = "01/"+this.utilityService.getMonthStrFromDate(new Date());
    this.getMonthListScholastic(); // lấy danh sách tháng
    console.log("ccccc");
  }



  ngOnInit() {
    console.log("iiiiiii");

    this.loadClasss();
    //khoản thu
    this.loadCategory();
    this.loadKhoicoso();
  }
  ngAfterViewInit() {
    // dropdown danh mục
    // this._script.load('./assets/js/scripts/form-plugins.js');
    // $('#sorter').selectpicker();
    // $('#viewNums').selectpicker();
  }
  loadKhoicoso() {

    let pagingObj = {
      viewNumsObj: 500,
      pageObj: 1
    };
    let filter = {
        type : environment.cateType_khoicoso
    }
    let sorter = {
      sortStr: 'od'
    }
    this.categoryeduService.loadCategory(filter, sorter, pagingObj).subscribe(
      async response => {
        if (response) {
            this.khoicosos = response.data;
        }
      },
      error => {
        console.error(error);
      })
  }
  onDateSelect($event) {
    // var temp = this.utilityService.getDateFromDDMMYYY(this.fromDateStr)
    this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
      this.loadStudent();
  }
  selectMonth($event) {
    // var temp = this.utilityService.getDateFromDDMMYYY(this.fromDateStr)
    // this.selectedDateStr = this.utilityService.getFullDateStrFromDate(this.fromDate),
      this.loadStudent();
  }

  viewNumsObjChange() {
    this.curentPage = 1;
    console.log(this.viewNumsObj);
    this.loadStudent();
  }
  pageChange = function (page) {
    if (page === 'prev' && this.curentPage > 1) {
      this.curentPage = this.curentPage - 1;
      this.loadStudent();
    } else if (page === 'next' && this.curentPage < this.listPage.length) {
      this.curentPage = this.curentPage + 1;
      this.loadStudent();
    } else if ((typeof page === 'number') && page !== this.curentPage) {
      this.curentPage = page;
      this.loadStudent();
    }
  };
  click_student(item) {
    this.student_selected = item;
    console.log(this.student_selected);
  }
  async getSubjectsByClass(class_code) {

    let data = {
      // hocky: this.appService.hockySelected,
      class_code: class_code
    };
    this.schoolService.getSubjectsAssignmentClass(data).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.subjects = response;
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }
  classRadioCheck(class_code) {
    console.log(class_code);
    // this.class_codeSelected = class_code;
    this.student_selected = new Student(); // chọn lớp thì reset student selected
    this.filter.class_code = class_code;
    this.loadStudent();
  }
  popup_mode(mode) {
    if (this.filter.class_code == "") {
      this.toastr.warning("Thầy cô chọn lớp để thao tác");
      return;
    }

    this.action_mode = mode;
    if (this.action_mode == 'camera') {
      this.loadStudent();
    } else if (this.action_mode == 'changeClass') {
      this.loadStudent();
    }
  }
  popup_arrange() {
    if (this.filter.class_code == "") {
      this.toastr.warning("Thầy cô chọn lớp để thao tác");
      return;
    }
    const dialogRef = this.dialog.open(ArrangeDataComponent, {
      data: this.students
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadStudent();
      }
    });
  }
  popupRegisterCameraImage(item) {

    const dialogRef = this.dialog.open(StudentRegisterImageCameraComponent, {
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.loadStudent();
      }
    });
  }
 
  selectPeriod_time(code, name, cap_hoc) {
    this.period_code = code;
    this.period_name = name;
    this.cap_hoc = cap_hoc;
    if (this.cap_hoc == 'THCS') {
      localStorage.setItem(environment.assessment_priod_type_thcs_name, name);
      localStorage.setItem(environment.assessment_priod_type_thcs_code, code);
    } else if (this.cap_hoc == 'TH') {
      localStorage.setItem(environment.assessment_priod_type_th_name, name);
      localStorage.setItem(environment.assessment_priod_type_th_code, code);
    }

    // reset student selected
    this.student_selected = new Student(); 
    this.loadStudent();

  }
  openNhapNhanhKetQua(){
    
  }
  // loadTotalPage() {
  //   // request to server
  //   this.teacherService.loadItemSum(this.filter)
  //     .subscribe(
  //       response => {
  //         console.log('ItemListComponent load Item');
  //         console.log(response);
  //         this.totalItems = response[0].NumberOfItems;
  //         let pages_temp = Math.ceil(this.totalItems / this.viewNumsObj);
  //         this.listPage = [];
  //         for (let i = 1; i <= pages_temp; i++) {
  //           this.listPage.push({ i: i });
  //         }
  //         console.log(pages_temp);
  //         console.log(this.listPage);
  //       },
  //       error => {
  //         console.error(error);
  //       });
  // };

  // resetteacher() {

  //   this.student = new Student();
  //   this.imageUrl = environment.student_avatar;
  //   this.fileteacherImg = null;
  //   // this.student.category_code = "DM";
  //   // this.student.category_code_brand = "BRAND";

  //   console.log(this.student);
  // }

  loadStudent() {
    let pagingObj = {
      viewNumsObj: this.viewNumsObj,
      pageObj: this.curentPage
    };
    // this.loadTotalPage();
    if (true) {
      this.checkboxs_changeClass = [];
      this.checkboxAllButton = false;
      this.filter.period_time = this.selectedDateStr
      this.filter.period_code = this.period_code
      this.isLoading_student = true;
      this.students = [];
      this.students_backup = [];
      this.studentService.loadStudentAssessmentPeriod(this.filter, this.sorter, pagingObj).subscribe(
        async response => {
          this.isLoading_student = false;
          console.log(response);
          if (response) {


            this.students = response.data;
            this.students_backup = response.data;
            this.checkboxs_changeClass = new Array(this.students.length);
            // this.appService.students = response.data;
            // this.appService.students_backup = response.data;
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.students.length;
            // for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;


          }
        },
        error => {
          this.isLoading_student = false;
        })
    }
  }
  checkboxAll(evt) {
    // for (let index = 0; index < this.checkboxs_changeClass.length; index++) {
    //   this.checkboxs_changeClass[index] = evt.target.checked;
    // }

    this.students.forEach(element => {
      element.changeClass_temp = evt.target.checked;
    });

  }
  doStudentChange($event) {
    if ($event.hasOwnProperty('reload') && $event.reload) {
      this.student_selected = new Student(); // chọn lớp thì reset student selected
      this.loadStudent();
      // cập nhật lại tất cả
    } else {
      // cập nhật 1 student
      this.students.forEach(element => {
        if (element.code == $event.student_code) {
          element.period_status = $event.status;
          element.period_attached_file = $event.period_attached_file;
          return;
        }
      });
    }
  }
  


  confirmSend() {
    if (this.filter.class_code == '') {
      this.toastr.warning("", "Thầy cô chưa chọn lớp để gửi");
      return;
    }

    if (!this.checkNumberAssessmentPeriod()) {
      this.toastr.warning("", "Yêu cầu nhập kết quả cho tất cả học sinh trong lớp");
      return;
    }

    let data = {
      title: 'Xác nhận gửi kết quả học tập của ' + this.students_numberAssessmentPeriod + "/" + this.students.length + " PHHS",
      msg: 'Đồng ý đề bắt đầu gửi thông báo',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        this.send(1); // check_sent: 1 check; 0 không check
        console.log("Bắt đầu gửi nè");
      }
    });
  }
  confirmReSend() {
    
    let data = {
      title: 'Thông báo đã được gửi trước đó ' + this.students_numberAssessmentPeriod + "/" + this.students.length + " PHHS",
      msg: 'Đồng ý đề bắt đầu gửi lại thông báo',
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        // close hoặc không close
        this.send(0);
        console.log("Bắt đầu gửi lại thông báo nè");
      }
    });
  }

  checkNumberAssessmentPeriod() {
    console.log();
    this.students_numberAssessmentPeriod = 0;
    this.students.forEach(element => {
      if (element.period_status == 0) { // -1 là null. 0 là đã nhập
        this.students_numberAssessmentPeriod++
      }
    });
    console.log(this.students_numberAssessmentPeriod == this.students.length ? true : false);
    return this.students_numberAssessmentPeriod == this.students.length ? true : false
  }
  send(check_sent) {
    let data = {
      class_code: this.filter.class_code,
      period_time: this.selectedDateStr,
      period_code: this.period_code,
      period_name: this.period_name,
      check_sent: check_sent
    }

    this.assessmentService.sendNotificationAssessmentPeriod(data).subscribe(
      async response => {
        this.isLoading = false;
        console.log(response);
        if (response.code == "ok" && response.sent == 1) {
          // this.toastr.warning("", "Thông báo đã được gửi trước đó. Thầy cô có muốn gửi lại ");
          this.confirmReSend();
        }
        if (response.code == "ok" && response.sent == 0) {
          this.toastr.success("", "Thông báo đã được gửi thành công");
        }
      },
      error => {
        this.isLoading = false;
        console.error("error:");
        console.error(error);
      })
  }


  async loadClasss() {
    let filter = {
      khoi_code: this.filter.khoi_code,
      type: environment.cateType_class,
      hocky: this.appService.hockySelected
    };
    this.schoolService.getAllClassByTeacher(filter).subscribe(
      async response => {
        // this.isLoading.dismiss();
        console.log(response);
        if (response) {
          this.classs = response;
          // chọn lớp thì reset student selected
          this.student_selected = new Student();
        }
      },
      error => {
        // this.isLoading.dismiss();
        console.error(error);
      })
  }

  popupUpdate(item, tab) {
    // mở popup
    const dialogRef = this.dialog.open(StudentUpdateComponent, {
      position: {
        // top: '0px',
        // left: '0px'
      },
      width: '600px',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: { item, tab }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.toastr.success("", "Cập nhật thành công");
        this.loadStudent();
      }
    });
  }
  popupDetailClass(){
    if(this.students.length == 0){
      this.toastr.warning("Thầy cô vui lòng chọn lớp", "Để xem chi tiết");
      return;
    }
    // mở popup
    const dialogRef = this.dialog.open(AssessmentPeriodViewComponent, {
      position: {
         top: '0px',
        right: '0px'
      },
      width: '50%',
      // height: '90%',
      panelClass: 'full-screen-modal',
      data: {'data':this.students}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result != "undefined" && result.code == "ok") {
        this.toastr.success("", "Cập nhật thành công");
        this.loadStudent();
      }
    });
  }

  popup_student_import() {
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    $("#import-student-modal").modal('show');
  }

  category_khoi_change(event) {
    this.loadClasss();
  }
  category_class_change(item) {
    console.log(item);
    this.student_selected = new Student(); // chọn lớp thì reset student selected
    this.cap_hoc = this.utilityService.getCapbyKhoi(item.khoi_code);

    if (this.cap_hoc == 'THCS') {
      if (localStorage.getItem(environment.assessment_priod_type_thcs_code)) {
        this.period_name = localStorage.getItem(environment.assessment_priod_type_thcs_name);
        this.period_code = localStorage.getItem(environment.assessment_priod_type_thcs_code);
      } else {
        this.period_name = this.dataService.period_thcs_list[0].name;
        this.period_code = this.dataService.period_thcs_list[0].code;
      }
    } else if (this.cap_hoc == 'TH') {
      if (localStorage.getItem(environment.assessment_priod_type_th_code)) {
        this.period_name = localStorage.getItem(environment.assessment_priod_type_th_name);
        this.period_code = localStorage.getItem(environment.assessment_priod_type_th_code);
      } else {
        this.period_name = this.dataService.period_th_list[0].name;
        this.period_code = this.dataService.period_th_list[0].code;
      }
    }

    this.loadStudent();
    this.getSubjectsByClass(this.filter.class_code);
  }
  category_change(event) {
    console.log(event);
    this.student_selected = new Student(); // chọn lớp thì reset student selected
    // this.cap_hoc = this.utilityService.getCapbyKhoi(this.khoi_code);
    this.loadStudent();
  }
  sort_change(event) {
    this.loadStudent();
  }

  
  // uploadAvatar(fileUpload: File, name: string) {
  //   console.log(fileUpload);
  //   if (fileUpload == null || typeof(fileUpload) == "undefined") {
  //     return;
  //   }
  //   let param = {
  //     des: environment.student,
  //     name: name
  //   }
  //   this.ioService.uploadImage(fileUpload, param).subscribe(
  //     async response => {
  //       this.isLoading = false;
  //       console.log('upload');
  //       console.log(response);
  //     },
  //     error => {
  //       this.isLoading = false;
  //       console.error(error);
  //     })
  // }

  // closePopup(){
  //   console.log("closePopup");
  //   $("#add-student-modal").modal('hide');
  // }


  changeListener($event): void {
    this.file = $event.target.files[0];
    console.log(this.file);
  }
  uploadAssessmentPeriodExcel() {

    if (this.file == null || typeof (this.file) == "undefined") {
      this.toastr.warning("Thầy cô chưa chọn file", "Vui lòng chọn file");
      return;
    }
    this.isLoading = true;
    console.log(this.file);
    this.selectedDateStr = this.utilityService.dayStrToMonthFull(this.selectedDateStr);
    this.studentService.uploadAssessmentPeriod(this.file, this.filter.class_code,this.cap_hoc, this.period_code, this.period_name, this.selectedDateStr).subscribe(
      async response => {
        this.isLoading = false;
        console.log('upload');
        console.log(response);
        if (response.code == "ok") {
          this.import_result.result = true;
          this.import_result.success = response.value;
          this.loadStudent();
        }else{
          this.import_result.result = false;
          this.import_result.msg = response.msg;
        }
        
      },
      error => {
        this.isLoading = false;
        this.import_result.result = false;
        this.import_result.msg = 'Đã có lỗi xảy ra khi đọc file Excel';
        console.error(error);
      })
  }
  popup_student_assessment_export() {
    // let filter = {};
    console.log(this.filter);
    this.studentService.exportStudentAssessment(this.filter.class_code,this.period_code, this.selectedDateStr);
  }
  //để hiển thị tháng
  selectedDateMMYYYYStr = '';
  openImport(){
    $(".blurButton").trigger("blur"); // bỏ focus để tránh enter
    this.import_result = {
      result: false,
      success: 0,
      fail: 0,
      msg: ""
    };
    this.selectedDateMMYYYYStr = this.utilityService.dayStrToMonth(this.selectedDateStr);
    $("#import-student-modal").modal('show');
  }
  clearSearch() {
    this.filter.name = '';
    this.loadStudent();
  }
  // teacherCodeTypeClick(type) {
  //   this.teacherCodeType = type;
  //   if (this.teacherCodeType == "mts") {
  //     this.student.code = environment.STUDENT_CODE_PREFIX + Date.now() ;
  //   } else if (this.teacherCodeType == "tn" || this.teacherCodeType == "qmv") {
  //     this.student.code = "";
  //   }
  // }
  // focusOutCheckCode() {

  //   let filter = {
  //     code: this.student.code,
  //   }
  //   console.log(filter);
  //   this.studentService.checkCode(filter).subscribe(
  //     response => {
  //       if (response) {
  //         if(response.code == 0){
  //           this.studentAddForm.controls.code.setErrors("Đã tồn tại code");
  //           this.toastr.error("Đã tồn tại code","Lỗi");
  //         }
  //       }
  //       console.log(response);
  //     },
  //     error => {
  //       console.error(error);
  //     })
  // }
  // async avatarChangeListener($event): Promise<void> {
  //   this.fileteacherImg = $event.target.files[0];
  //   if (this.fileteacherImg == null) {
  //     return;
  //   }
  //   console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
  //   console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
  //   try {
  //     this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
  //       this.fileteacherImg = new File([result], result.name);
  //       // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

  //       var reader = new FileReader();
  //       reader.readAsDataURL(this.fileteacherImg);
  //       reader.onload = (_event) => {
  //         this.imageUrl = reader.result;
  //       }
  //     },
  //       error => {
  //         console.log('error', error);
  //       })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // async avatarChangeUpdateListener($event): Promise<void> {
  //   this.fileteacherImg = $event.target.files[0];
  //   if (this.fileteacherImg == null) {
  //     return;
  //   }
  //   console.log('originalFile instanceof Blob', this.fileteacherImg instanceof Blob); // true
  //   console.log(`originalFile size 1 ${this.fileteacherImg.size / 1024} KB`);
  //   try {
  //     this.ng2ImgMax.resizeImage(this.fileteacherImg, 300, 300).subscribe(result => {
  //       this.fileteacherImg = new File([result], result.name);
  //       // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);

  //       var reader = new FileReader();
  //       reader.readAsDataURL(this.fileteacherImg);
  //       reader.onload = (_event) => {
  //         this.studentSelected_imageUrl = reader.result;
  //       }
  //     },
  //       error => {
  //         console.log('error', error);
  //       })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // showImage(image_url) {
  //   return image_url !== "" ? 'url(' + this.appService.getUrlImage(image_url) + ')' : 'url(' + environment.student_avatar + ')';
  // }
  //for search
  getTypingSearch(ev: any) {

    // Reset items back to all of the items
    this.students = this.students_backup;

    // set val to the value of the searchbar
    const val = ev.target.value.trim();

    // if the value is an empty string don't filter the items
    if (val && val.length > 2) {
      this.students = this.students.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }


  // drop(event: CdkDragDrop<number[]>) {
  //   if (event.previousContainer === event.container) {
  //     console.log(event.previousIndex, event.currentIndex);
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(
  //       event.previousContainer.data,
  //       event.container.data,
  //       event.previousIndex,
  //       event.currentIndex,
  //     );
  //     console.log(event.previousIndex, event.currentIndex);
  //     console.log(event.previousIndex, event.currentIndex);
  //   }
  // }
  // /** Predicate function that only allows even numbers to be dropped into a list. */
  // evenPredicate(item: CdkDrag<number>) {
  //   return item.data % 2 === 0;
  // }

  // /** Predicate function that doesn't allow items to be dropped into a list. */
  // noReturnPredicate() {
  //   return false;
  // }




  formatter = (x: { name: string }) => x.name;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      tap(() => {
        this.searching = true;
        console.log("11111");
        // this.filter.name = this.searchStr;
        // console.log(this.filter.name);
        this.loadStudent();
      }),
      map(term => term === '' ? [] : this.students.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
      tap(() => { }),
    );
  onClick(r) {
    this.filter.name = "";
    this.popupUpdate(r, "");
  }



  kt_viewNumsObjChange() {
    this.kt_curentPage = 1;
    console.log(this.kt_viewNumsObj);
    this.loadCategory();
  }
  kt_pageChange = function (page) {
    if (page === 'prev' && this.kt_curentPage > 1) {
      this.kt_curentPage = this.kt_curentPage - 1;
      this.loadCategory();
    } else if (page === 'next' && this.kt_curentPage < this.kt_listPage.length) {
      this.kt_curentPage = this.kt_curentPage + 1;
      this.loadCategory();
    } else if ((typeof page === 'number') && page !== this.kt_curentPage) {
      this.kt_curentPage = page;
      this.loadCategory();
    }
  };

  kt_filter = {
    name: "",
    khoi_code: "",
    deleted: 0,
    type: environment.cateType_finance
  }
  kt_sorter = {
    sortStr: this.appService.sort_category[2].code,
  }
  //for paging
  kt_viewNumsObj = this.appService.viewNums[1].Code;
  kt_curentPage = 1;
  kt_totalItemOnPage: any;
  kt_totalItems: any;
  kt_listPage: any = [];
  loadCategory() {

    let pagingObj = {
      viewNumsObj: this.kt_viewNumsObj,
      pageObj: this.kt_curentPage
    };

    this.categoryeduService.loadCategory(this.kt_filter, this.kt_sorter, pagingObj).subscribe(
      async response => {
        if (response) {
          this.categorys = response.data;

          this.kt_totalItems = response.totalItems;
          this.kt_totalItemOnPage = this.categorys.length;
          //for select page
          let pages_temp = response.totalPage;
          this.kt_listPage = [];
          for (let i = 1; i <= pages_temp; i++) {
            this.kt_listPage.push({ i: i });
          }
          // this.searching = false;

        }
      },
      error => {
        console.error(error);
      })
  }
  getMonthListScholastic() {


    this.schoolService.getMonthListScholastic({}).subscribe(
      async response => {
        console.log(response);
        this.monthList = response;
      },
      error => {
        console.error(error);
      })
  }
}



