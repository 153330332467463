<div class="row mb-2 mt-2 ">
    <div class="col-8 p-0 ">
        <!-- <div ngbDropdown class="d-inline-block">
            <button class="btn btn-2 btn-primary btn-labeled btn-labeled-left btn-icon  mr-2" ngbDropdownToggle>
                <span class="btn-label"><img class="img-circle" src="./assets/img/icons/chart.png" alt="image"
                        width="35" /></span>
                {{type_report}}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="selectReport(appService.type_report_detail_week)" class="text-primary">
                    <img class="icon-image-btn  mr-2" src="./assets/img/icons/excel.png" alt="image" width="20" />
                    {{appService.type_report_detail_week}}
                    <span class="my-block-20"></span>
                </button>
                <button ngbDropdownItem (click)="selectReport(appService.type_report_detail_month)" class="text-primary">
                    <img class="icon-image-btn  mr-2" src="./assets/img/icons/excel.png" alt="image" width="20" />
                    {{appService.type_report_detail_month}}
                    <span class="my-block-20"></span>
                </button>
            </div>
        </div> -->

        <mat-button-toggle-group [value]="type_report" #group="matButtonToggleGroup" (change)="onValChange(group.value)" appearance="" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle [value]="appService.type_report_detail_week">
                <span class="material-icons mr-2"> event </span> Báo cáo tuần</mat-button-toggle>
            <mat-button-toggle [value]="appService.type_report_detail_month">
                <span class="material-icons mr-2"> calendar_month </span> Báo cáo tháng</mat-button-toggle>
        </mat-button-toggle-group>

    </div>

    <div class="col-4  p-0 d-flex justify-content-end ">

        <mat-form-field appearance="fill" style="width: 200px;" class="mr-3">
            <mat-label>Tổ</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                    {{item.name}} ({{item.teacherSum}})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>


    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill" *ngIf="type_report == appService.type_report_day">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>


<div *ngIf="type_report == appService.type_report_detail_week || type_report == appService.type_report_detail_month">
    <div class="row mb-2">

        <div class="col-12 d-flex justify-content-between">
            <div>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-primary"> check_circle </span>
                    </span>
                    Đúng giờ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-warning"> report </span>
                    </span>
                    Muộn/trễ
                </button>
                <button class="btn btn-secondary btn-labeled btn-labeled-left btn-icon mr-2 ml-2">
                    <span class="btn-label">
                        <span class="material-icons text-danger"> report </span>
                    </span>
                    Vắng
                </button>
            </div>

            <button (click)="confirmDownloadExcel()" class="btn btn-secondary btn-labeled btn-labeled-left btn-icon">
                <span class="btn-label"><img src="./assets/img/icons/excel.png" alt="image" width="20" /></span>
                Tải xuống
            </button>
        </div>
    </div>
    <div class="qbs_scroll" [style.width.px]="screenWidth - 240">
        <table class="table table-head-purple table-hover" id="datatable">
            <thead class="thead-default thead-lg">
                <tr>
                    <th class="headcol" style="text-align: center;">
                        Cán bộ giáo viên
                        <div style="font-size: small;">
                            Chi tiết điểm danh
                        </div>
                    </th>

                    <td class="headrow" *ngFor="let item of dateRange; let i = index">
                        <div class="text-center" [ngClass]="{'active': item.ddmmyyyy == this.selectedDateStr, 'text-warning': (item.day == 0 || item.day == 6) }" style="min-width: 60px;">
                            <div style="font-size: small;">{{item.dayOfWeek}}</div>
                            <div>{{item.ddmm}}</div>
                        </div>
                    </td>
                </tr>
            </thead>
            <!-- <tbody> -->
            <tr *ngFor="let item of this.teachers; let i = index" [ngClass]="{'bg-primary-100': false }">
                <th class="headcol">
                    <div class="d-flex justify-content-between align-items-center">
                        <a class="d-flex justify-content-start align-items-center">
                            <span class="mr-2"> <span class="badge badge-default ">{{i+1}}</span></span>
                            <div>
                                <div>
                                    {{item.name}}
                                </div>
                                <div class="text-secondary" style="font-size: 11px;">
                                    {{item.code}}
                                    <span class="badge-secondary badge-point"></span>
                                    <!-- <span class="badge badge-secondary badge-pill" style="padding: 2px 8px;">{{item.cate_checkin_time_names}}</span> -->
                                    {{item.phone}}

                                </div>
                            </div>
                        </a>
                        <div>
                            {{item.datas_sum.attention}}/ {{item.datas_sum.late}}/ {{item.datas_sum.total}}
                        </div>
                    </div>
                </th>


                <td *ngFor="let item_datas of item.datas; let j = index" class="long">
                    <!-- tooltip -->

                    <div *ngIf="item_datas.checkin_times.length > 0" class="datas-tooltip">
                        <span *ngFor="let item3 of item_datas.checkin_times; let k = index" class="badge badge-primary badge-pill" style="font-size: unset;
                        margin: 3px;
                        font-weight: normal;">
                            {{item3}}
                        </span>
                    </div>
                    <!-- Danh sách khung giờ -->

                    <div class="w_t_btn">
                        <div *ngFor="let item_cate_checkin of item_datas.cate_checkins; let j = index">


                            <div *ngIf="item_cate_checkin.status > 0" class="btn-group mb-1 mr-2 ml-2">
                                <button class="btn btn-secondary btn-icon-only btn-sm" data-toggle="dropdown" style="width: 35px;">
                                    <span class="btn-label">
                                        <span *ngIf="item_cate_checkin.status == 1" class="material-icons text-primary">
                                            check_circle </span>
                                        <span *ngIf="item_cate_checkin.status == 2" class="material-icons text-warning">
                                            report </span>
                                        <span *ngIf="item_cate_checkin.status == 3" class="material-icons text-danger">
                                            report </span>
                                        <!-- hiển thị số lượng giờ -->
                                        <span *ngIf="item_datas.checkin_times.length > 0" class="bag_num_checkin">
                                            {{item_datas.checkin_times.length}}
                                        </span>
                                    </span>
                                </button>
                                <div class="btn-full dropdown-menu dropdown-menu-right" x-placement="bottom-end" style="position: absolute; transform: translate3d(157px, 30px, 0px); top: 0px; left: 0px; will-change: transform;">
                                    <button class="dropdown-item" href="javascript:;" (click)="updateCheckin(item_datas, item_cate_checkin, item.code, item.name, 'one')" [disabled]="!(item_cate_checkin.status == 3)">Điểm danh</button>
                                    <button class="dropdown-item" href="javascript:;" (click)="updateCheckin(item_datas, item_cate_checkin, item.code, item.name, 'all')">Điểm danh tất cả</button>
                                    <button class="dropdown-item" href="javascript:;" (click)="popupTooltip(item_datas)" [disabled]="!(item_datas.checkin_times.length > 0)">Xem ảnh điểm danh</button>
                                </div>
                                <button class="btn btn-secondary" (click)="popupTooltip(item_datas)">{{item_cate_checkin.cate_checkin_time_name}}</button>
                            </div>

                        </div>
                    </div>
                </td>


            </tr>
            <!-- </tbody> -->
        </table>
    </div>
</div>
<div *ngIf="type_report == 'Báo cáo chi tiết tháng'">
    Báo cáo chi tiết tuần
</div>

<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>


<div class="modal fade" id="popupTooltip-modal">
    <div class="modal-dialog modal-dialog-full" role="document">
        <form class="modal-content form-horizontal">
            <div class="modal-header p-4">
                <h5 class="modal-title">Chi tiết</h5>
            </div>
            <div class="modal-body p-4">
                <table>
                    <tr *ngFor="let item3 of item_datas.checkin_times; let k = index" class="row-items-checkin">
                        <td class="timeline-box-left">
                            {{item3}}
                            <span></span>
                        </td>
                        <td class="timeline-box-right">
                            <div class="content-item">
                                <img [src]="appService.showCheckinImage(item_datas.detected_image_urls[k])" style="max-height: 200px;
                                border-radius: 5px;" />
                            </div>
                        </td>
                    </tr>
                </table>
                <div *ngIf="item_datas.checkin_times != undefined  && item_datas.checkin_times.length == 0">
                    Không có dữ liệu điểm danh
                </div>
            </div>
        </form>
    </div>
</div>