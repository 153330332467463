<!-- <div class="page-heading">
    <h1 class="page-title">Danh sách cán bộ giáo viên</h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a href="#"><span class="material-icons sidebar-item-icon-material">home</span></a>
        </li>
        <li class="breadcrumb-item">Danh sách cán bộ giáo viên</li>
    </ol>
</div> -->

<div class="page-content fade-in-up">

    <div style="height: 48px;" class="row justify-content-between">
        <div class="col p-0">
            <div class="d-flex justify-content-start">

                <mat-form-field class="example-full-width" appearance="fill" style="width: 280px;">
                    <mat-label>Tìm kiếm cán bộ giáo viên</mat-label>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput [(ngModel)]="filter.name" [ngbTypeahead]="search" placeholder="Nhập họ tên" name="search" maxlength="200">
                    <a *ngIf="filter.name" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </a>
                </mat-form-field>

                <!-- <div class="m-1"></div>
                <div class="">


                    <mat-form-field appearance="fill" style="width: 120px;" class="">
                        <mat-label>Chức vụ</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="filter.positions" (selectionChange)="category_change($event)">
                            <mat-option [value]="" [disabled]="false">
                                Tất cả
                            </mat-option>
                            <mat-option *ngFor="let item of appService.positions" [value]="item.code" [disabled]="false">
                                {{item.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div> -->
                <div class="m-1"></div>
                <div class="">

                    <mat-form-field appearance="fill" style="width: 120px;" class="">
                        <mat-label>Tổ</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                            <mat-option [value]="" [disabled]="false">
                                Tất cả
                            </mat-option>
                            <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>

                <div class="m-1"></div>
                <div class="">

                    <mat-form-field appearance="fill" style="width: 150px;" class="">
                        <mat-label>Sắp xếp</mat-label>
                        <mat-select [disabled]="false" [(ngModel)]="sorter.sortStr" (selectionChange)="sort_change($event)">

                            <mat-option *ngFor="let item of appService.sort_teachers" [value]="item.code" [disabled]="false">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <div class="col-4 p-0">
            <div class="d-flex justify-content-end">

                <div class="p-2">
                    <div class="btn-highlight " [ngClass]="{'active' : isHighlight }">
                        <button [disabled]="isLoading" class="blurButton btn  btn-primary btn-labeled btn-labeled-left btn-icon" (click)="updateTeachingAssignment()">
                        <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                        <span class="btn-label" *ngIf="!isLoading"><span class="material-icons">save</span></span>
                        Lưu phân công
                    </button>
                    </div>
                </div>
                <div class="p-2">

                    <!-- <div class="btn-group">
                        <div class="btn" *ngFor="let item of appService.hockys; let i = index" [ngClass]="{'btn-primary': item.code == this.appService.hockySelected, 'btn-outline-primary': item.code != this.appService.hockySelected}" (click)="hocky_change(item.code)">{{item.value}}</div>
                    </div> -->
                    <div ngbDropdown class="d-inline-block">

                        <button class="blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" ngbDropdownToggle>
                            <span class="btn-label"><i class="ti ti-more-alt"></i></span>Thêm
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="hocky_change('I')"><span class="material-icons">looks_one
                                </span>
                                Học kỳ I</button>
                            <button ngbDropdownItem (click)="hocky_change('II')"><span class="material-icons">looks_two
                                </span>
                                Học kỳ II</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-8" style="padding: 0;">
            <div class="pcgd-cover">
                <div>
                    <div class="row  row-header bg-primary">
                        <div class="col-4">
                            Giáo viên
                        </div>
                        <div class="col-8">
                            Kết quả
                        </div>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row row-body hoverClick hasImage" *ngFor="let item of this.teachers; let i = index" [style.background-image]="appService.showImageTeacher(item.image_url)" [ngClass]="{'indexSelected' : i == teacherIndexSelected,'hasData' : item.teachingAssignment !='', 'disable-cbgv': !(appService.checkFullPermission() || item.code == appService.currentUser.code) }"
                        (click)="clickTeacher(i)">
                        <div class="col-4">
                            <div style="white-space: nowrap;
                            overflow: hidden !important;
                            text-overflow: ellipsis;">{{i+1}}. {{item.name}} </div>
                        </div>
                        <div class="col-8 teachingAssignment">
                            <span>{{utility.formatTeachingAssignmentJsonToStr(item.teachingAssignment)}} </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-2">
            <div class="pcgd-cover">
                <div>
                    <div class="row row-header bg-primary">
                        <div class="col">
                            Môn dạy
                        </div>

                    </div>
                </div>
                <div class="panel-body">
                    <div *ngIf="appService.currentUser.caps.length > 1" class="text-center">
                        <div class="btn-group btn-group-sm" style="margin-top: -2px">
                            <div class="btn " *ngFor="let item of appService.currentUser.caps; let i = index" [ngClass]="{'btn-primary': item.code == this.appService.capSelected, 'btn-outline-primary': item.code != this.appService.capSelected}" (click)="caphoc_change(item.code)">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="row row-body" *ngFor="let item of this.categorys_subject; let i = index">
                        <div class="col hoverClick" [ngClass]="{'indexSelected' : i == subjectIndexSelected,'hasData' : utility.checkExistSubjects(item.code, teacherSelected)}" (click)="clickSubjects(i)">
                            <span class="text-info">{{item.subject_cap}}</span> {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-2 p-0">
            <div class="pcgd-cover">
                <div>
                    <div class="row row-header bg-primary">
                        <div class="col">
                            <label class="checkbox checkbox-inline checkbox-warning" style="line-height: 21px; width: 100%;">
                                <input type="checkbox" (click)="toggleClassSelect($event)">
                                <span class="input-span"></span>Học kỳ {{this.appService.hockySelected}}
                            </label>

                            <!-- <span>Học kỳ {{this.appService.hockySelected}}</span> -->
                        </div>
                        <!-- <div class="col">
                            Học kỳ II
                        </div> -->
                    </div>
                </div>
                <div class="panel-body">
                    <div class="row  row-body" *ngFor="let item of this.categorys_class; let i = index">
                        <div class="col hoverClick">
                            <label class="checkbox checkbox-inline checkbox-primary" style="line-height: 21px; width: 100%;">
                                <input type="checkbox" [value]="item.code" ngM
                                    [checked]="utility.checkExistClass(item.code, hockySelectedList.class)"
                                    (click)="selectClass($event,i)"  (input)="onInputChange()">
                                <span class="input-span"></span>{{item.name}}</label>
                        </div>

                    </div>
                    <div *ngIf="categorys_class ==  undefined || categorys_class.length == 0" class="text-center mt-5" style="font-size: small;">
                        <img style="width: 40px;" src="assets\img\icons\file-and-folder.png" alt="">
                        <div class="mb-2">Thầy/Cô chưa chọn môn học</div>
                        <div>hoặc môn học chưa được gán vào lớp ( Vào menu môn học chọn Gán môn học cho lớp)</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row align-items-center mb-2">
        <div class="col-4">
            <span class="">[Tổng {{totalItems}} ] Hiển thị {{ ((curentPage - 1) * viewNumsObj)+1}} đến
                {{((curentPage - 1) * viewNumsObj)+totalItemOnPage }}</span>
        </div>
        <div class="col-8 d-flex justify-content-end">
            <ul class="pagination p-1" style="margin-bottom: 0px;">
                <li>
                    <select class="form-control-sm" name="viewNums" id="viewNums" [(ngModel)]="viewNumsObj" (change)="viewNumsObjChange()">
                        <option *ngFor="let item of appService.viewNums" [ngValue]="item.Code">{{item.Value}}</option>
                    </select>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('prev')" aria-label="Previous">
                        <span aria-hidden="true">
                            <i class="ti ti-angle-left"></i>
                        </span>
                    </a>
                </li>
                <li *ngFor="let item of listPage" class="page-item active" (click)="pageChange(item.i)" [ngClass]="{'active' : item.i == curentPage}">
                    <a class="page-link" href="javascript:;">{{item.i}}</a>
                </li>
                <li class="page-item">
                    <a class="page-link page-link-solid" (click)="pageChange('next')" aria-label="Next">
                        <i class="ti ti-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div> -->
</div>