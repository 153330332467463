
  import { Component, OnInit, ViewChild } from '@angular/core';
  import { ToastrService } from 'ngx-toastr';
  import { Ng2ImgMaxService } from 'ng2-img-max';
  import { FormControl, FormGroup, Validators } from '@angular/forms';
  import { UtilityService } from 'src/app/_services/utility.service';
  import { AppService } from 'src/app/_services/app.service';
  import { Category } from 'src/app/model/category';
  import { IoService } from 'src/app/_services/io.service';
  import { Observable } from 'rxjs';
  import {  debounceTime,  map, tap } from 'rxjs/operators';
  import { CategoryEduService } from 'src/app/_services/category-edu.service';
  import { MatDialog } from '@angular/material/dialog';
  import { TeacherPicker2Component } from 'src/app/components/teacher-picker2/teacher-picker2.component';
import { environment } from 'src/environments/environment';
import { HealthAttributeComponent } from '../health-attribute/health-attribute.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
  declare var $: any;
  @Component({
    selector: 'app-health-cate',
    templateUrl: './health-cate.component.html',
    styleUrls: ['./health-cate.component.css']
  })
  export class HealthCateComponent implements OnInit {
    @ViewChild('addForm') addForm: HTMLFormElement;
    @ViewChild('updateForm') updateForm: HTMLFormElement;
  
    isLoading = false;
    formGroup: FormGroup;
    // for create
    CodeType = 'tn';  //mts
    CodeTemp = "";
    file: File = null;
    imageUrl: any = environment.product_avatar;
    
    categorys : any;
    categorys_backup: any
    
    cateType = environment.cateType_health;
    // for add
    cate = new Category(this.cateType);
    // for update
    categoryselected: any;
    categoryselected_file: File = null;
    categoryselected_imageUrl: any = environment.product_avatar;
    isDeleted: boolean;
  
    //for paging
    viewNumsObj = this.appService.viewNums[1].Code;
    curentPage = 1;
    totalItemOnPage: any;
    totalItems: any;
    listPage: any = [];
    //for search
    searching = false;
  
    sorter = {
      sortStr: this.appService.sort_category[4].code,
    }
    
    filter = {
      name: "",
      type: this.cateType
    }
  
  
  
    constructor(
      public categoryeduService: CategoryEduService,
      public ioService: IoService,
      private toastr: ToastrService,
      public appService: AppService,
      private ng2ImgMax: Ng2ImgMaxService,
      private utility: UtilityService,
      public dialog: MatDialog,
    ) {
      this.appService.page_title = "Danh mục sức khỏe";
      this.categoryselected = this.cate;
      // this.countAllCategory();
    }
  
    ngOnInit() {
      this.loadCategory();
      this.formGroup = new FormGroup({
        Email: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
        ]),
        Password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20)
        ])
      });
    }
    onSubmit() {
      console.log(this.formGroup);
    }
  
    onReset() {
      this.formGroup.reset();
    }
    ngAfterViewInit() {
      // $("#form-add-category").validate({
      //   rules: {
      //     name: {
      //       // minlength: 2,
      //       required: !0
      //     },
      //   },
      //   errorClass: "help-block error",
      //   highlight: function (e) {
      //     $(e).closest(".form-group.row").addClass("has-error")
      //   },
      //   unhighlight: function (e) {
      //     $(e).closest(".form-group.row").removeClass("has-error")
      //   },
      // });
    }
    clearSearch(){
      this.filter.name='';
      this.loadCategory();
    }
    resetCategory() {
      this.cate = new Category(this.cateType);
      this.file == null;
      this.imageUrl = environment.product_avatar; 
    }
  
    loadCategory() {
  
      let pagingObj = {
        viewNumsObj: this.viewNumsObj,
        pageObj: this.curentPage
      };
  
      this.categoryeduService.loadCategory(this.filter, this.sorter , pagingObj).subscribe(
        async response => {
          if (response) {
            this.categorys = response.data;
            this.categorys_backup = response.data;
            
            this.totalItems = response.totalItems;
            this.totalItemOnPage = this.categorys.length;
            //for select page
            let pages_temp = response.totalPage;
            this.listPage = [];
            for (let i = 1; i <= pages_temp; i++) {
              this.listPage.push({ i: i });
            }
            this.searching = false;
  
          }
        },
        error => {
          console.error(error);
        })
    }
    insertCategory() {
      
      if (this.cate.name.trim() == "") {
        this.toastr.warning("", "Vui lòng nhập mã");
        return false;
      }
  
      this.cate.code = this.utility.convertViToEn(this.cate.name);
    
  
      if(this.file !== null){
        this.cate.image_url = this.cateType + "/" + this.cate.code + "." + this.utility.getExtensionFile(this.file);
      }
     
      
      if (!this.addForm.invalid) {
        this.isLoading = true;
        this.categoryeduService.insertCategory(this.cate).subscribe(
          async response => {
            this.isLoading = false;
            console.log(response);
            if (response) {
              this.toastr.success("", "Thêm mới danh mục thành công");
              $("#add-category-modal").modal('hide');
              this.resetCategory();
              this.loadCategory();
            }
            else {
              this.toastr.error("", "Mã đã tồn tại hoặc có lỗi xảy ra");
            }
          },
          error => {
            this.isLoading = false;
            this.toastr.error("Có lỗi xảy ra", "Vui lòng thử lại hoặc liên hệ CSKH");
            console.error(error);
          })
        this.uploadAvatar(this.file, this.cate.code);
      }
    }
    /////////////////
    openCateHealthSub(itemSelected, index) {
  
      this.categoryselected = itemSelected;
      const dialogRef = this.dialog.open(HealthAttributeComponent, {
        position: {
          // left: '10px',
          // right: '10px'
        },
        // width: '95%',
        // height: '95%',
        panelClass: 'full-screen-modal',
        data: this.categoryselected
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log("close");
      
        if (typeof result != "undefined" && result.code == true) {
          console.log("result.data", result.data);
          // load lại dữ liệu
          this.loadCategory();
        }
      });
  
  
    }
    ganToTruong(item) {
  
      let data = {
        code: this.categoryselected.code,
        leader_code: item.code,
      }
      this.categoryeduService.ganToTruong(data).subscribe(
        async response => {
          console.log(response);
          if (response.code == "ok") {
            this.toastr.success("", "Gán tổ trưởng thành công");
            $("#confirm-gvcn-modal").modal('hide'); // tắt popup xác nhận
            this.loadCategory();
          }else{
            this.toastr.error("", "Đã có lỗi xảy ra");
          }
        },
        error => {
      
          console.error(error);
        })
  
    }
    uploadAvatar(fileUpload : File, name : string) {
      console.log(fileUpload);
      if(fileUpload == null){
        return;
      }
      let param = {
        des: this.cateType,
        name: name
      }
      this.ioService.uploadImage(fileUpload, param).subscribe(
        async response => {
          this.isLoading = false;
          console.log('upload');
          console.log(response);
          
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
    }
    countAllCategory() {
      this.categoryeduService.countAllCategory(this.cateType).subscribe(
        response => {
          this.cate.code = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
          this.CodeTemp = environment.CATEGORY_CODE_PREFIX + (parseInt(response.CountAllCategory) + 1).toString();
          // console.log("this.cate.code",this.cate.code);
        },
        error => {
  
          console.error(error);
        })
  
    }
    getTypingSearch(ev: any) {
  
      // Reset items back to all of the items
      this.categorys = this.categorys_backup;
  
      // set val to the value of the searchbar
      const val = ev.target.value.trim();
      console.log(ev.target.value.trim());
      // if the value is an empty string don't filter the items
      if (val && val.length > 2) {
        this.categorys = this.categorys.filter((item) => {
          return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
        })
      }
    }
    popup_category_add() {
      this.resetCategory();
      this.countAllCategory();
      $("#add-category-modal").modal('show');
    }
    popupUpdate(item) {
      this.categoryselected = item;
      this.categoryselected_imageUrl = this.appService.showImageProduct(item.image_url);
      this.isDeleted = this.categoryselected.deleted == 0 ? false : true;
      $("#update-category-modal").modal('show');
    }
    
    updateCategory() {
  
      if (this.categoryselected.name.trim() == "") {
        this.toastr.warning("", "Vui lòng nhập tên");
        return false;
      }
      if(this.categoryselected_file !== null){
        this.categoryselected.image_url = this.cateType +"/" + this.categoryselected.code + "." + this.utility.getExtensionFile(this.categoryselected_file);
        console.log("1111");
      }
      this.categoryselected.deleted = this.isDeleted ? 1 : 0;
      console.log(this.categoryselected);
  
      this.isLoading = true;
      this.categoryeduService.update(this.categoryselected).subscribe(
        async response => {
          this.isLoading = false;
          console.log(response);
          if (response.code=="ok") {
            this.loadCategory();
            $("#update-category-modal").modal('hide');
            this.toastr.success("", "Cập nhật danh mục thành công");
  
          }
        },
        error => {
          this.isLoading = false;
          console.error(error);
        })
        this.uploadAvatar(this.categoryselected_file, this.categoryselected.code);
    }
  
    async changeListener($event): Promise<void> {
      this.file = $event.target.files[0];
      if(this.file == null){
        return;
      }
      console.log('originalFile instanceof Blob', this.file instanceof Blob); // true
      console.log(`originalFile size 1 ${this.file.size / 1024} KB`);
      try {
        this.ng2ImgMax.resizeImage(this.file, 300, 300).subscribe(result => {
          this.file = new File([result], result.name);
          // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);
  
          var reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = (_event) => {
            this.imageUrl = reader.result;
            console.log(this.imageUrl);
          }
        },
          error => {
            console.log('error', error);
          })
      } catch (error) {
        console.log(error);
      }
    }
    async changeImageUpdate($event): Promise<void> {
      this.categoryselected_file = $event.target.files[0];
      if(this.categoryselected_file == null){
        return;
      }
  
      try {
        this.ng2ImgMax.resizeImage(this.categoryselected_file, 300, 300).subscribe(result => {
          this.categoryselected_file = new File([result], result.name);
          // console.log(`originalFile size 2 ${this.file.size / 1024} KB`);
  
          var reader = new FileReader();
          reader.readAsDataURL(this.categoryselected_file);
          reader.onload = (_event) => {
            this.categoryselected_imageUrl = reader.result;
            console.log(this.categoryselected_imageUrl);
          }
        },
          error => {
            console.log('error', error);
          })
      } catch (error) {
        console.log(error);
      }
    }
    
    codeTypeClick(type) {
      this.CodeType = type;
      if (this.CodeType == "mts") {
        this.cate.code = this.CodeTemp;
      } else if (this.CodeType == "tn") {
        this.cate.code = "";
      }
    }
    focusOutCheckCode() {
      let filter = {
        code: this.cate.code,
        type: this.cateType
      }
      this.categoryeduService.checkCode(filter).subscribe(
        response => {
          if (response) {
            if(response.code == 0){
              this.addForm.controls.code.setErrors("Đã tồn tại code");
              this.toastr.error("Đã tồn tại code","Lỗi");
              console.log(this.addForm.controls.code.errors);
              console.log("kkkkk", this.addForm.controls.code.errors);
            }
          }
          console.log(response);
        },
        error => {
          console.error(error);
        })
    }
  
    viewNumsObjChange() {
      this.curentPage = 1;
      console.log(this.viewNumsObj);
      this.loadCategory();
    }
    pageChange = function (page) {
      if (page === 'prev' && this.curentPage > 1) {
        this.curentPage = this.curentPage - 1;
        this.loadCategory();
      } else if (page === 'next' && this.curentPage < this.listPage.length) {
        this.curentPage = this.curentPage + 1;
        this.loadCategory();
      } else if ((typeof page === 'number') && page !== this.curentPage) {
        this.curentPage = page;
        this.loadCategory();
      }
    };
  
    //for search
    formatter = (x: { name: string }) => x.name;
  
    search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(400),
        tap(() => {
          this.searching = true;
          console.log("11111");
          // this.filter.name = this.searchStr;
          // console.log(this.filter.name);
          this.loadCategory();
        }),
        map(term => term === '' ? [] : this.categorys.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 15)),
        tap(() => { }),
      );
      onClick(r) {
        this.filter.name = "";
        this.popupUpdate(r);
      }
   /////////////////
   saveArrange() {
    let data = [];
    this.categorys.forEach((item, i) => {
      data.push({
        id: item.id,
        od: i
      });
    });
    console.log(data);

    this.categoryeduService.saveArrange(data).subscribe(
      async response => {
        if (response.code == "ok") {
          this.toastr.success("","Lưu sắp xếp thành công");
        }
      },
      error => {
        console.error("error:");
        console.error(error);
      })


  }

  drop(event: CdkDragDrop<number[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      console.log(event.previousIndex, event.currentIndex);
      console.log(event.previousIndex, event.currentIndex);
    }
  }
  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false;
  }
  
  }
  