<div class="m-2">
    <h4 style="width: 350px;font-weight: bold;">Hướng dẫn thanh toán</h4>
    <div class="text-center"><strong>{{data.student.full_name}}</strong></div>
</div>
<mat-dialog-content>

    <div *ngIf="data.finance.merge_code == null" class="d-flex justify-content-between align-items-center finance-item">
        <div>{{data.finance.name}}</div>
        <div><span style="font-weight: bold;">{{data.finance.finance_value | qbscurrency}}</span></div>
    </div>
    <div *ngIf="data.finance.merge_code != null" class="d-flex justify-content-between align-items-center finance-item">
        <div>{{data.finance.merge_name}}</div>
        <div><span style="font-weight: bold;">{{data.finance.merge_value | qbscurrency}}</span></div>
    </div>
    <div class="mt-3">
        <strong>Cách 1:</strong> Quét mã QRcode thanh toán
    </div>
    <div class="p-2">
        <div class="d-flex justify-content-center  align-items-center" style="min-width: 300px; min-height: 300px;">
            <img [src]="'data:image/jpg;base64,' + paymentMethod.base64">
        </div>
    </div>
    <div class="mt-3">
        <strong>Cách 2:</strong> Nhập mã tài khoản để chuyển khoản
    </div>
    <div class="p-2">
        <div [innerHTML]="paymentMethod.bankInfo"></div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="ml-2 blurButton btn  btn-secondary btn-labeled btn-labeled-left btn-icon" (click)="onCancelClick()">
        <span class="btn-label"><span class="material-icons">clear</span></span>Đóng
    </button>
</mat-dialog-actions>