<style>
    .login-content {
        max-width: 900px;
        margin: 0px auto;
    }
    
    .link2:hover {
        font-weight: bold;
        color: var(--secondary) !important;
    }
    
    .login-content-cover {
        height: 100%;
        background: #0099CC;
        background-repeat: no-repeat;
        background-position: top;
        background-size: 25%;
    }
    
    .form-control {
        font-size: 19px;
    }
    
    .auth-head-icon {
        position: relative;
        height: 60px;
        width: 60px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        background-color: #fff;
        color: #5c6bc0;
        box-shadow: 0 5px 20px #d6dee4;
        border-radius: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
    
    body.empty-layout .theme-config,
    body.empty-layout .to-top {
        display: none !important;
    }
</style>

<div class="login-content-cover d-flex align-items-center">
    <div class="row login-content mb-5">
        <div class="col-sm-6 bg-white">
            <div class=" m-0" style="border-radius: 5px;">
                <form class="" id="loginForm">
                    <h2 class="text-center mb-5 mt-3 font-weight-bold">Đăng nhập</h2>

                    <div class="form-group text-center" style="min-width: 364px; font-size: 19px;">
                        <div class="mb-2">
                            <!-- <label class="radio radio-inline radio-grey radio-warning">
                                <input type="radio" name="login_role"  value="admin" [(ngModel)]="user.login_role" (change)="handleChangeLoginRole($event)">
                                <span class="input-span"></span>Admin</label> -->

                            <label class="radio radio-inline radio-grey radio-warning">
                                    <input type="radio" name="login_role"  value="cbgv" [(ngModel)]="user.login_role" (change)="handleChangeLoginRole($event)">
                                    <span class="input-span"></span>Giáo viên</label>
                            <label class="radio radio-inline radio-grey radio-warning" placement="top" ngbTooltip="PHHS vui lòng tải app QBSEdu để đăng nhập">
                                    <input type="radio" name="login_role"  value="phhs" disabled="true" [(ngModel)]="user.login_role" (change)="handleChangeLoginRole($event)">
                                    <span class="input-span"></span>Phụ huynh</label>

                        </div>
                    </div>

                    <!-- <div *ngIf="user.login_role == 'admin'" class="form-group mb-4 mt-4">
                        <input [(ngModel)]="user.school_code" (ngModelChange)="user.school_code = $event.toUpperCase()" (keydown.space)="$event.preventDefault();" class="form-control form-control-air" type="text" name="school_code" placeholder="Mã trường">
                    </div> -->

                    <div *ngIf="user.login_role == 'cbgv'" class="form-group mb-4  mt-4">
                        <mat-form-field class="" appearance="fill">
                            <mat-label>Email/Tài khoản</mat-label>
                            <input matInput [(ngModel)]="user.email" placeholder="Email/Tài khoản" name="email" maxlength="200">
                        </mat-form-field>

                        <!-- <input [(ngModel)]="user.email" class="form-control form-control-air" type="text" name="email" placeholder="Email"> -->
                    </div>

                    <div *ngIf="user.login_role == 'phhs' " class="form-group mb-4  mt-4">
                        <mat-form-field class="" appearance="fill">
                            <mat-label>Số điện thoại</mat-label>
                            <input matInput [(ngModel)]="user.phone" placeholder="Số điện thoại" name="phone" maxlength="200">
                        </mat-form-field>
                        <!-- <input [(ngModel)]="user.phone" class="form-control form-control-air" type="text" name="phone" placeholder="Số điện thoại"> -->
                    </div>

                    <div class="form-group mb-4">
                        <mat-form-field class="" appearance="fill">
                            <mat-label>Mật khẩu</mat-label>
                            <input matInput [(ngModel)]="user.password" [type]="hide ? 'password' : 'text'" name="password" placeholder="Mật khẩu" maxlength="200">
                            <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </a>
                        </mat-form-field>

                        <!-- <input [(ngModel)]="user.password" class="form-control form-control-air" type="password" name="password" placeholder="Mật khẩu"> -->
                    </div>

                    <div class="flexbox mb-5 mt-3">
                        <label class="checkbox checkbox-inline checkbox-primary">
                            <input type="checkbox" checked="true">
                            <span class="input-span"></span>Nhớ đăng nhập</label>

                        <a class="text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Hotline hỗ trợ 0896.888.102">Quên mật khẩu?</a>
                    </div>
                    <div class="text-center mb-3">
                        <button (click)="login()" class="btn btn-primary  btn-air" [disabled]="isLoading">
                            <span class="btn-label" [ngClass]="{'spinner-border': isLoading}"><i
                                    class="ti ti-check"></i></span>
                            Đăng nhập
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-6 d-inline-flex align-items-center text-white py-4 px-5">
            <div>
                <!-- <div class="h2 mb-4">QBSEdu</div> -->
                <img style="width: 120px;" src="assets/img/logo.png" alt="" />
                <div class="h2 mb-4">Chuyển đổi số trong quản lý giáo dục</div>
                <div class="mb-4 pt-3">
                    <button class="btn  btn-icon-only btn-circle mr-3"><a
                            href="https://www.facebook.com/giaiphapsoqbs"><img style="width: 120px;" src="assets/img/icons/icon-facebook.png" alt="" /></a></button>
                    <button class="btn  btn-icon-only btn-circle mr-3"><a href="https://zalo.me/g/wextkh826"><img style="width: 120px;" src="assets/img/icons/icon-zalo.png" alt="" /></a></button>
                </div>
                <p>Công cụ quản lý sáng tạo cho quản lý giáo dục</p>
                Hướng dẫn cài đặt ứng dụng AIS Connect : <a class="link2" target="_blank" href="https://www.youtube.com/watch?v=5YVZlHLlT-s">Link youtube</a>
                <!-- <div class="flexbox-b mb-3"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
                <!-- <div class="flexbox-b mb-3"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
                <!-- <div class="flexbox-b mb-5"><i class="ti-check mr-3 text-success"></i>Lorem Ipsum dolar set.</div> -->
                <!-- <button class="btn btn-outline btn-rounded btn-fix">Register</button> -->
            </div>
        </div>
    </div>
</div>