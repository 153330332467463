<div class="component">
    <div class="component-avatar">
        <img style="width: 55px;" src="assets\img\icons\medal.png" alt="">
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <div class="" style="width: 400px; margin-left: 12px;">
            <h4>Sổ điểm - {{student_full_name}}</h4>
            <span *ngIf="data.cap_hoc == 'TH'" style="font-weight: bold;">Kết quả học tập và rèn luyện -
                <span *ngIf="data.period_code == 'th_period_ht'" style="font-weight: bold; color: var(--danger);" > tháng: {{data.period_time}} 
                </span>
            <span *ngIf="data.period_code == 'th_period_hki' || data.period_code == 'th_period_cnh'" style="font-weight: bold; color: var(--danger);"> {{data.period_name}} 
                </span>
            </span>
            <!-- <span style="font-weight: bold;" *ngIf="data.cap_hoc == 'THCS'">Kỳ: {{data.period_name}}</span> -->
        </div>
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <button *ngIf="this.data.id == 0" class="blurButton btn btn-primary btn-labeled btn-labeled-left btn-icon mr-2" (click)="save()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> post_add </span></span>
                    Lưu kết quả
                </button>
                <button *ngIf="this.data.id > 0" class="blurButton btn btn-warning btn-labeled btn-labeled-left btn-icon mr-2" (click)="update()">
                    <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                    <span class="btn-label" *ngIf="!isLoading"><span class="material-icons"> beenhere </span></span>
                    Cập nhật kết quả
                </button>





            </div>
        </div>
    </div>

    <div *ngIf="student_code ==  undefined || student_code == ''" class="text-center m-5 p-5">
        <img style="width: 80px;" src="assets\img\icons\file-and-folder.png" alt="">
        <div>Thầy/Cô chưa chọn học sinh. Vui lòng chọn lớp sau đó chọn học sinh</div>
    </div>

    <ng-container *ngIf="data.cap_hoc == 'THCS'">
        <div *ngIf="student_code != ''" class="health-input-cover qbs-scroll">
            <div class="panel panel-monhoc-thcs">
                <h4 class="title" style="color: #ef882a;">
                    <span class="material-icons mr-2">verified</span>Đợt: {{data.period_name}}
                </h4>
                <div class="body">
                    <table class="table-border">
                        <tr class="title">
                            <td style="width: 45px; text-align: center;">
                                STT
                            </td>
                            <td style="width: 160px; text-align: center;">
                                Môn học
                            </td>
                            <ng-container *ngIf="data.period_code == 'thcs_period_dn' || data.period_code == 'thcs_period_gki' || data.period_code == 'thcs_period_gkii' || data.period_code == 'thcs_period_cki' || data.period_code == 'thcs_period_ckii'">
                                <td style="width: 100px; text-align: center;">
                                    Điểm
                                </td>
                            </ng-container>
                            <!-- <ng-container *ngIf="data.period_code == 'thcs_period_cki' || data.period_code == 'thcs_period_ckii'">
                                <td style="width: 100px; text-align: center;">
                                    Điểm thi
                                </td>
                                <td style="width: 100px; text-align: center;">
                                    Điểm TBM
                                </td>
                            </ng-container> -->
                            <ng-container *ngIf="data.period_code == 'thcs_period_cn' || data.period_code == 'thcs_period_cki' || data.period_code == 'thcs_period_ckii'">
                                <td style="width: 100px; text-align: center;">
                                    Điểm TBM
                                </td>
                            </ng-container>
                            <td style="text-align: center;">
                                <ng-container *ngIf="data.period_code != 'thcs_period_cn'">
                                    Nhận xét
                                </ng-container>
                                <ng-container *ngIf="data.period_code == 'thcs_period_cn'">
                                    Đánh giá
                                </ng-container>
                            </td>

                        </tr>
                        <!-- // lặp qua các môn học -->
                        <ng-container *ngIf="subjects.length == 0" class="align-items-center">
                            <tr>
                                <td colspan="5">
                                    <div class="text-center text-warning m-5">Lớp chưa được gán môn học (Menu: Môn học, bấm nút Gán môn học cho lớp)</div>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let item of subjects; let i = index">

                            <tr>
                                <td style="text-align: center;">
                                    {{i+1}}
                                </td>
                                <td style=" text-align: center;">
                                    {{item.subjects_name}}
                                </td>
                                <ng-container *ngIf="data.period_code == 'thcs_period_dn' || data.period_code == 'thcs_period_gki' || data.period_code == 'thcs_period_gkii' || data.period_code == 'thcs_period_cki' || data.period_code == 'thcs_period_ckii'">
                                    <td style=" text-align: center;">
                                        <input class="input2 mark2" [id]="'mark_'+ i" [name]="'mark_'+ i" maxlength="3" [(ngModel)]="item['mark']" (keypress)="mark_keypress($event)" (focusout)="markFocusoutFunction(i,'mark',item['mark'])">
                                    </td>
                                </ng-container>

                                <ng-container *ngIf="data.period_code == 'thcs_period_cn' || data.period_code == 'thcs_period_cki' || data.period_code == 'thcs_period_ckii'">
                                    <td style=" text-align: center;">
                                        <input class="input2 mark2" [id]="'mark_tbm_'+ i" [name]="'mark_tbm_'+ i" maxlength="3" [(ngModel)]="item['mark_tbm']" (keypress)="mark_keypress($event)" (focusout)="markFocusoutFunction(i,'mark_tbm',item['mark_tbm'])">
                                    </td>
                                </ng-container>



                                <td>
                                    <textarea type="text" rows="2" class="input2" [(ngModel)]="item['nx']"></textarea>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                    <ng-container *ngIf="data.period_code == 'thcs_period_cki' || data.period_code == 'thcs_period_ckii' || data.period_code == 'thcs_period_cn'">
                        <div>
                            <ng-container *ngFor="let item of data.danhgia_gvcn_thcs_ck; let i = index" class="student-item">
                                <div style="font-weight: bold;">{{item.name}}</div>
                                <textarea type="text" [(ngModel)]="item.value" [rows]="item.rows" class="input3"></textarea>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div class="footer">

                </div>
                <ng-container *ngIf="data.period_code != 'thcs_period_cn'">


                    <div class="panel panel-monhoc">
                        <div class="title" style="color: #ef882a; font-weight: bold;">
                            Ý kiến của Giáo viên chủ nhiệm:
                        </div>
                        <div class="m-1"></div>
                        <div class="body">

                            <ng-container *ngFor="let item of data.ykien_gvcn_thcs; let i = index" class="student-item">
                                <div>{{item.name}}</div>
                                <textarea type="text" [(ngModel)]="item.value" rows="3" class="input3"></textarea>
                            </ng-container>

                        </div>
                        <div class="footer">

                        </div>
                    </div>
                    <div class="panel panel-monhoc">
                        <div class="title" style="color: #00739d; font-weight: bold;">
                            Ý kiến của Phụ huynh:
                        </div>
                        <div class="body">
                            <textarea type="text" readonly [(ngModel)]="data.ykien_ph" rows="5" class="input2"></textarea>
                        </div>
                        <div class="footer">

                        </div>
                    </div>
                </ng-container>
                <div class="panel panel-dinhkem">
                    <div class="filettach-upload mb-3">
                        File đính kèm
                        <input type="file" #myInput accept="application/pdf, image/jpeg, image/png" id="fileAttach" name="fileAttach" (change)="filePickerListener($event)">
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="data.cap_hoc == 'TH'">
        <div *ngIf="student_code != ''" class="health-input-cover qbs-scroll">
            <ng-container *ngIf="data.period_code == 'th_period_ht'">
                <!-- đợt hằng tháng -->
                <div class="panel panel-monhoc">
                    <h4 class="title" style="color: #ef882a;">
                        <span class="material-icons">assignment</span>MÔN TOÁN
                    </h4>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td>
                                    Đọc, viết số
                                </td>
                                <td>
                                    Kỹ năng tính
                                </td>
                                <td>
                                    Kỹ năng giải toán
                                </td>
                                <td>
                                    Kỹ năng sử dụng đồ dùng học toán
                                </td>
                                <td>
                                    Kỹ năng liên quan đến yếu tố hình học và đo lường
                                </td>
                                <td>
                                    Kỹ năng liên quan đến xác suất, thống kê
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <!-- <div class="title">Đọc, viết số</div> -->
                                    <ng-container *ngFor="let item of data.toan_1; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng tính</div> -->
                                    <ng-container *ngFor="let item of data.toan_2; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng giải toán</div> -->
                                    <ng-container *ngFor="let item of data.toan_3; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng sử dụng đồ dùng học toán</div> -->
                                    <ng-container *ngFor="let item of data.toan_4; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng liên quan đến yếu tố hình học và đo lường</div> -->
                                    <ng-container *ngFor="let item of data.toan_5; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng liên quan đến xác suát, thống kê</div> -->
                                    <ng-container *ngFor="let item of data.toan_6; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr class="title">
                                <td colspan="6" class="title" style="text-align: center; background-color: #c3e6f4;">
                                    Đánh giá chung về các năng lực toán học
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <table>
                                        <tr>
                                            <ng-container *ngFor="let item of data.toan_7; let i = index" class="student-item">
                                                <td>
                                                    <label class="checkbox-cover checkbox checkbox-primary">
                                                    {{item.name}}
                                                    <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                                    <span class="input-span"></span></label>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <div class="panel panel-monhoc">
                    <h4 class="title" style="color: #00739d;">
                        <span class="material-icons">assignment</span>MÔN TIẾNG VIỆT
                    </h4>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td>
                                    Chữ viết
                                </td>
                                <td colspan="2" style="text-align: center;">
                                    Kỹ năng nghe - viết, nhớ - viết
                                </td>
                                <td>
                                    Kỹ năng viết văn
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <!-- <div class="title">Đọc, viết số</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_1; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng tính</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_2; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng giải toán</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_3; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng sử dụng đồ dùng học toán</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_4; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr class="title">
                                <td colspan="2" style="text-align: center; background-color: #c3e6f4;">
                                    Kỹ năng đọc
                                </td>
                                <td colspan="2" style="text-align: center; background-color: #c3e6f4;">
                                    Kỹ năng nói và nghe
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <!-- <div class="title">Đọc, viết số</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_5; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng tính</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_6; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng giải toán</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_7; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng sử dụng đồ dùng học toán</div> -->
                                    <ng-container *ngFor="let item of data.tiengviet_8; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <div class="panel panel-monhoc">
                    <h4 class="title" style="color: #ef882a;">
                        <span class="material-icons">assignment</span>MÔN TIẾNG ANH
                    </h4>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td>
                                    Kỹ năng nghe
                                </td>
                                <td>
                                    Kỹ năng nói
                                </td>
                                <td>
                                    Kỹ năng đọc
                                </td>
                                <td>
                                    Kỹ năng viết
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <!-- <div class="title">Đọc, viết số</div> -->
                                    <ng-container *ngFor="let item of data.tienganh_1; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng tính</div> -->
                                    <ng-container *ngFor="let item of data.tienganh_2; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng giải toán</div> -->
                                    <ng-container *ngFor="let item of data.tienganh_3; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng sử dụng đồ dùng học toán</div> -->
                                    <ng-container *ngFor="let item of data.tienganh_4; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <div class="panel panel-monhoc">
                    <h4 class="title" style="color: #00739d;">
                        <span class="material-icons">assignment</span>MÔN KỸ NĂNG SỐNG
                    </h4>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td>
                                    Kỹ năng được học
                                </td>
                                <td>
                                    Mức độ nhận biết
                                </td>
                                <td>
                                    Kỹ năng thực hành
                                </td>
                                <td>
                                    Vận dụng vào cuộc sống
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <textarea type="text" [(ngModel)]="data.kns_1" class="input2" rows="3"></textarea>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng tính</div> -->
                                    <ng-container *ngFor="let item of data.kns_2; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng giải toán</div> -->
                                    <ng-container *ngFor="let item of data.kns_3; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng sử dụng đồ dùng học toán</div> -->
                                    <ng-container *ngFor="let item of data.kns_4; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <!-- CÔNG TÁC CHĂM SÓC -->
                <div class="panel panel-monhoc">
                    <h4 class="title" style="color: #ef882a;">
                        <span class="material-icons">health_and_safety</span>CÔNG TÁC CHĂM SÓC
                    </h4>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td>
                                    Giờ ăn, ngủ
                                </td>
                                <td>
                                    Đồng phục
                                </td>
                                <td>
                                    Vệ sinh
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <!-- <div class="title">Kỹ năng tính</div> -->
                                    <ng-container *ngFor="let item of data.ctcs_1; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng giải toán</div> -->
                                    <ng-container *ngFor="let item of data.ctcs_2; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- <div class="title">Kỹ năng sử dụng đồ dùng học toán</div> -->
                                    <ng-container *ngFor="let item of data.ctcs_3; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <!-- Ý THỨC HỌC TẬP VÀ RÈN LUYỆN -->
                <div class="panel panel-monhoc">
                    <h4 class="title" style="color:#00739d;">
                        <span class="material-icons">spa</span>Ý THỨC HỌC TẬP VÀ RÈN LUYỆN
                    </h4>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td colspan="2">
                                    Ý thức học tập
                                </td>
                                <td colspan="2">
                                    Ý thức rèn luyện
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <ng-container *ngFor="let item of data.ythuc_1; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let item of data.ythuc_2; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let item of data.ythuc_3; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngFor="let item of data.ythuc_4; let i = index" class="student-item">
                                        <label class="checkbox-cover checkbox checkbox-primary">
                                        {{item.name}}
                                        <input type="checkbox" [value]="item.id" [(ngModel)]="item.checked">
                                        <span class="input-span"></span></label>
                                    </ng-container>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer">

                    </div>
                </div>



                <div class="panel panel-monhoc">
                    <div class="title" style="color: #ef882a; font-weight: bold;">
                        Ý kiến của Giáo viên chủ nhiệm:
                    </div>
                    <div class="body">
                        <textarea type="text" [(ngModel)]="data.ykien_gvcn" rows="5" class="input2"></textarea>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <div class="panel panel-monhoc">
                    <div class="title" style="color: #00739d; font-weight: bold;">
                        Ý kiến của Phụ huynh:
                    </div>
                    <div class="body">
                        <textarea type="text" readonly [(ngModel)]="data.ykien_ph" rows="5" class="input2"></textarea>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <div class="panel panel-dinhkem">
                    <div class="filettach-upload mb-3">
                        File đính kèm
                        <input type="file" #myInput accept="application/pdf, image/jpeg, image/png" id="fileAttach" name="fileAttach" (change)="filePickerListener($event)">
                    </div>
                </div>
            </ng-container>
            <!-- Đợt học kỳ và cả năm học -->
            <ng-container *ngIf="data.period_code == 'th_period_hki' || data.period_code == 'th_period_cnh'">
                <div class="panel panel-monhoc-thcs">
                    <h4 class="title" style="color: #ef882a;">
                        <span class="material-icons mr-2">verified</span>KẾT QUẢ CÁC MÔN HỌC & HOẠT ĐỘNG GIÁO DỤC
                    </h4>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td style="width: 45px; text-align: center;" rowspan="2">
                                    STT
                                </td>
                                <td style="width: 160px; text-align: center;" rowspan="2">
                                    Môn
                                </td>
                                <td style="width: 160px; text-align: center;" colspan="2">
                                    Kết quả đánh giá
                                </td>
                            </tr>
                            <tr class="title">

                                <td style="width: 160px; text-align: center;">
                                    Điểm
                                </td>
                                <td style="width: 160px; text-align: center;">
                                    Mức đạt được
                                </td>
                            </tr>
                            <!-- // lặp qua các môn học -->
                            <ng-container *ngIf="subjects.length == 0" class="align-items-center">
                                <tr>
                                    <td colspan="4">
                                        <div class="text-center text-warning m-5">Lớp chưa được gán môn học (Menu: Môn học, bấm nút Gán môn học cho lớp)</div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let item of subjects; let i = index">
                                <tr class="row_mark">
                                    <td style="text-align: center;">
                                        {{i+1}}
                                    </td>
                                    <td style=" text-align: center;">
                                        {{item.subjects_name}}
                                    </td>
                                    <td style=" text-align: center;">
                                        <input class="input2 mark2" [id]="'mark_'+ i" [name]="'mark_'+ i" maxlength="3" [(ngModel)]="item['mark']" (keypress)="mark_keypress($event)" (focusout)="markFocusoutFunction(i,'mark',item['mark'])">
                                    </td>
                                    <td style=" text-align: center;">

                                        <select class="custom-select" [(ngModel)]="item['mark_mdd']" name="'mark_mdd'+ i">
                                            <option [ngValue]="item.code"  *ngFor="let item of dataService.kqmhvhdgdList">{{item.name}}</option>
                                        </select>

                                        <button class="showNoteBtn btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Nhập lại" (click)="resetMark(item)">
                                            <span  class="material-icons"> refresh </span>
                                        </button>
                                        <!-- <input class="input2 mark2" [id]="'mark_mdd'+ i" [name]="'mark_mdd'+ i" maxlength="3" [(ngModel)]="item['mark_mdd']" (keypress)="mark_keypress($event)" (focusout)="markFocusoutFunction(i,'mark_mdd',item['mark_mdd'])"> -->
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                        <ng-container *ngIf="data.period_code == 'thcs_period_cki' || data.period_code == 'thcs_period_ckii'">
                            <div>
                                <ng-container *ngFor="let item of data.danhgia_gvcn_thcs_ck; let i = index" class="student-item">
                                    <div style="font-weight: bold;">{{item.name}}</div>
                                    <textarea type="text" [(ngModel)]="item.value" [rows]="item.rows" class="input3"></textarea>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <div class="panel panel-monhoc-thcs">
                    <h4 class="title" style="color: #00739d;">
                        <span class="material-icons mr-2">verified</span>KẾT QUẢ ĐÁNH GIÁ VỀ PHẨM CHẤT, NĂNG LỰC
                    </h4>
                    <h5 class="title">
                        <span class="material-icons mr-2 font-18">verified</span>NHỮNG PHẨM CHẤT CHỦ YẾU
                    </h5>

                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td style="width: 45px; text-align: center;">
                                    STT
                                </td>
                                <td style="width: 160px; text-align: center;">
                                    Phẩm chất
                                </td>
                                <td style="width: 160px; text-align: center;">
                                    Mức đạt được
                                </td>
                            </tr>
                            <ng-container *ngFor="let item of data.th_phamchatchuyeu; let i = index" class="student-item">
                                <tr class="row_mark">
                                    <td style="text-align: center;">
                                        {{item.id}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{item.name}}
                                    </td>
                                    <td style="text-align: center;">

                                        <select class="custom-select" [(ngModel)]="item.value" name="'th_phamchatchuyeu'+ i">
                                            <option [ngValue]="item.code"  *ngFor="let item of dataService.kqdgnlvpcList">{{item.name}}</option>
                                        </select>

                                        <button class="showNoteBtn btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Nhập lại" (click)="resetMark(item)">
                                            <span  class="material-icons"> refresh </span>
                                        </button>

                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                    <h5 class="title mt-2">
                        <span class="material-icons mr-2 font-18">verified</span>NHỮNG NĂNG LỰC CỐT LÕI
                    </h5>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td style="width: 45px; text-align: center;">
                                    STT
                                </td>
                                <td style="width: 160px; text-align: center;">
                                    Năng lực chung
                                </td>
                                <td style="width: 160px; text-align: center;">
                                    Mức đạt được
                                </td>
                            </tr>
                            <ng-container *ngFor="let item of data.th_nanglucchung; let i = index" class="student-item">
                                <tr class="row_mark">
                                    <td style="text-align: center;">
                                        {{item.id}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{item.name}}
                                    </td>
                                    <td style="text-align: center;">
                                        <select class="custom-select" [(ngModel)]="item.value" name="'th_nanglucchung'+ i">
                                            <option [ngValue]="item.code"  *ngFor="let item of dataService.kqdgnlvpcList">{{item.name}}</option>
                                        </select>

                                        <button class="showNoteBtn btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Nhập lại" (click)="resetMark(item)">
                                            <span  class="material-icons"> refresh </span>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                    <div class="body">
                        <table class="table-border">
                            <tr class="title">
                                <td style="width: 45px; text-align: center;">
                                    STT
                                </td>
                                <td style="width: 160px; text-align: center;">
                                    Năng lực đặc thù
                                </td>
                                <td style="width: 160px; text-align: center;">
                                    Mức đạt được
                                </td>
                            </tr>
                            <ng-container *ngFor="let item of data.th_nanglucdacthu; let i = index" class="student-item">
                                <tr class="row_mark">
                                    <td style="text-align: center;">
                                        {{item.id}}
                                    </td>
                                    <td style="text-align: center;">
                                        {{item.name}}
                                    </td>
                                    <td style="text-align: center;">
                                        <select class="custom-select" [(ngModel)]="item.value" name="'th_nanglucdacthu'+ i">
                                            <option [ngValue]="item.code"  *ngFor="let item of dataService.kqdgnlvpcList">{{item.name}}</option>
                                        </select>
                                        <button class="showNoteBtn btn btn-sm btn-primary btn-icon-only btn-circle  btn-thick" placement="top" ngbTooltip="Nhập lại" (click)="resetMark(item)">
                                            <span  class="material-icons"> refresh </span>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                    <div class="footer">

                    </div>
                </div>
                <ng-container *ngIf="data.period_code == 'th_period_cnh'">
                    <div class="panel panel-monhoc-thcs">
                        <h4 class="title" style="color: #ef882a; ">
                            <span class="material-icons mr-2">verified</span>KẾT QUẢ ĐÁNH GIÁ GIÁO DỤC
                        </h4>
                        <div class="panel panel-monhoc">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="title mr-2" style="font-weight: bold;">
                                    Đánh giá kết quả giáo dục:
                                </div>
                                <div class="body">
                                    <select class="custom-select" [(ngModel)]="data.th_danhgiaketquagiaoduc" name="'th_danhgiaketquagiaoduc'">
                                        <option [ngValue]="item.name"  *ngFor="let item of dataService.th_danhgiaketquagiaoduc">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div class="panel panel-monhoc">
                            <div class="title" style="font-weight: bold;">
                                Những thành tích nổi bật:
                            </div>
                            <div class="body">
                                <textarea type="text" [(ngModel)]="data.th_nhungthanhtichnoibat" rows="5" class="input2"></textarea>
                            </div>

                        </div>
                        <div class="panel panel-monhoc">
                            <div class="title" style="font-weight: bold;">
                                Khen thưởng cuối năm học:
                            </div>
                            <div class="body">
                                <textarea type="text" [(ngModel)]="data.th_khenthuongcuoinamhoc" rows="5" class="input2"></textarea>
                            </div>

                        </div>
                    </div>
                </ng-container>
                <div class="panel panel-dinhkem">
                    <div class="filettach-upload mb-3">
                        File đính kèm
                        <input type="file" #myInput accept="application/pdf, image/jpeg, image/png" id="fileAttach" name="fileAttach" (change)="filePickerListener($event)">
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>



<div class="modal fade" id="import-modal">
    <div class="modal-dialog" role="document">
        <form class="modal-content form-horizontal" id="form-import-student">
            <div class="modal-header p-4">
                <h5 class="modal-title">Nhập kết quả file Excel</h5>
                <a data-dismiss="modal"><i class="ti ti-close"></i></a>
            </div>
            <div class="modal-body p-4">

                <div *ngIf="!import_result.result">
                    <div class="form-group mb-4">
                        <label class="btn btn-primary  mr-2" style="width: 100%;">
                            <span class="btn-icon"><i class="la la-upload"></i>Chọn file upload</span>
                            <input type="file" accept=".xls,.xlsx" id="uploadFile" name="uploadFile"
                                (change)="changeListener($event)">
                        </label>
                    </div>
                    <div class="form-group mb-4">
                        <a style="color: blue;" href="{{template_file_url}}"> <i class="la la-download"></i>Tải mẫu nhập Excel
                        </a>
                    </div>
                    <div class="form-group mb-4">
                        <div>Hướng dẫn: Tải file mẫu về máy tính và nhập dữ liệu vào file mẫu, sau đó import file lên hệ thống
                        </div>
                    </div>
                </div>

                <div *ngIf="import_result.result">
                    <div class="text-success pb-3">Import thành công: {{import_result.success}}</div>
                    <div class="text-danger">Import thất bại: {{import_result.fail}}</div>
                </div>

            </div>
            <div class="modal-footer justify-content-center bg-primary-50">

                <div class="row align-items-center">
                    <div class="col text-center">

                        <button *ngIf="!import_result.result" class="btn btn-primary  btn-labeled btn-labeled-left btn-icon" (click)="uploadExcel()" [disabled]="isLoading">
                            <span class="btn-label" *ngIf="isLoading"><span class="spinner-border"></span></span>
                            <span class="btn-label" *ngIf="!isLoading"><i class="ti ti-upload"></i></span>
                            Import</button>

                        <button *ngIf="import_result.result" class="btn btn-primary " data-dismiss="modal">Đóng</button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>