import { environment } from './../../environments/environment';

export class Student {
    name : string = "";
    code: string ="";
    price: number;
    price_in: number;
    category_code ="DM";
    category_code_brand = "BRAND";
    image_url:string = "";
    description:string ="";
    unit ="";
    packing ="";
    color ="sa";
    ///// teacher
    positions ="giaovien";
    faculty_code ="default";
    password ="Demo@123";
    email = "";
    phone = "";
    gender = 0;
    birthday = "";
    // studen
    class_code = "";
    khoi_code = "";
    dan_toc = "";
    khoi = "";
    address = "";
    full_name = "";
    mother_name = "";
    mother_phone = "";
    father_name = "";
    father_phone = "";
    guardian_name = '';
    guardian_phone = '';
    constructor() {
        this.dan_toc = "Kinh";
    }
}
