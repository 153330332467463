import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppService } from './app.service';
@Injectable({
  providedIn: 'root'
})
export class CategoryEduService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('UserService constructor');
  }

  getClassByGVCNCode(cate) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/getClassByGVCNCode'), cate,).map(data => { return data; });
  }
  insertCategory(cate) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu'), cate,).map(data => { return data; });
  }
  saveMultiCategorys(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/saveMultiCategorys'), data,).map(data => { return data; });
  }
  checkCode(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/checkCode'), filter,).map(data => { return data; });
  }
  loadAllCategory(filter) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/getAll'), filter,).map(data => { return data; });
  }
  loadCategoryClassBySubject(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/getClassBySubject'), data,).map(data => { return data; });
  }
  loadCategory(filter,sorter,pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/get'), data,).map(data => { return data; });
  }
  update(cate) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/code/'+cate.code), cate,).map(data => { return data; });
  }
  
  updateAny(cate) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/any/code/'+cate.code), cate,).map(data => { return data; });
  }
  updateByID(cate) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/somedatabyid'), cate,).map(data => { return data; });
  }
  ganGiaoVienChuNhiem(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/gangiaovienchunhiem/code/'+data.code), data,).map(data => { return data; });
  }
  ganToTruong(data) {
    return this.http.put<any>(this.appService.getApiEndpoint('categoryedu/somedata/code/'+data.code), data,).map(data => { return data; });
  }
  countAllCategory(type) {
    return this.http.get<any>(this.appService.getApiEndpoint('categoryedu/count/'+type), {},).map(data => { return data; });
  }
  saveArrange(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('categoryedu/saveArrange'), data).map(data => { return data; });
  }


  

  // uploadImage(file, param) {
  //   const formData = new FormData();
  //   formData.append('uploadFile', file, file.name);
  //   formData.append('action', "UPLOAD_IMAGE");
  //   formData.append('shop_code', this.appService.currentUser.shop_code);
  //   formData.append('user_id', this.appService.currentUser.id.toString());
  //   formData.append('des', param.des);
  //   formData.append('name', param.name);
  //   return this.http.post<any>(this.appService.getApiEndpoint('upload.php?action=UPLOAD_IMAGE'), formData).map(data => { return data; });
  // }
}




