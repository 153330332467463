import { environment } from "src/environments/environment";

export class Category {
    id: number = 0;
    name : string = "";
    code: string = '';
    value: number = 0;
    value_date: string = '';
    code_parent: string = '';
    image_url: string ="";
    type: string ="";
    deleted: number = 0;

    subject_type: string ="";  //Bổ sung Chức danh cho bác sĩ

    // for type scholastic and  năm học
    hockyI_startTime: Date;
    hockyI_endTime: Date ;
    hockyII_startTime: Date;
    hockyII_endTime: Date;
    // for type checkin_time khung giờ làm việc
    checkin_end:string ="";
    checkin_start:string ="";
    checkin_early: number = 10;
    checkin_late: number = 10;
    checkin_effective: number = 0;
    checkin_weeks:string ="";
    // khối lớp của type class
    khoi: string ="";
    khoi_code: string ="";

    //for finance cate
    finance_value: number =0;
    finance_unit: string ="";
    finance_frequency: string ="";
    finance_type: string ="";
    finance_content: string ="";
    //subject
    // subject_type: string =""; // trùng với chức danh của bác sỹ
    subject_factor: number = 1;
    subject_type_mark: string ="";
    subject_cap: string ="";
    subject_lesson_w_i: number = 1;
    subject_lesson_w_ii: number = 1;
    // for TKB
    created_date: Date;

    fileUpload: File;
    constructor(type:string) {
       this.type = type;
    }
}
