import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(public http: HttpClient,
    private appService: AppService) {
    console.log('FinanceService constructor');
  }
  loadStudent(filter, sorter, pagingObj) {
    let data = {
      filter: filter,
      sorter: sorter,
      pagingObj: pagingObj
    };
    return this.http.post<any>(this.appService.getApiEndpoint('finance/get'), data,).map(data => { return data; });
  }
  reportClassList(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/report/classList'), data,).map(data => { return data; });
  }
  search(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/search'), data,).map(data => { return data; });
  }
  getByStudentsAndCateId(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/getByStudentsAndCateId'), data,).map(data => { return data; });
  }
  getByStudentsAndCateIds(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/getByStudentsAndCateIds'), data,).map(data => { return data; });
  }
  reportSchool(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/report/school'), data,).map(data => { return data; });
  }
  detailStudentCateFinance(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/detailStudentCateFinance'), data,).map(data => { return data; });
  }
  saveMulti(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/saveMulti'), data,).map(data => { return data; });
  }
  saveUpdate(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/saveUpdate'), data,).map(data => { return data; });
  }
  sendNoti(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/sendNoti'), data,).map(data => { return data; });
  }
  updateRefund(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/updateRefund'), data,).map(data => { return data; });
  }
  loadQRCode(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/loadQRCode'), data,).map(data => { return data; });
  }
  mergeFinance(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/mergeFinance'), data,).map(data => { return data; });
  }
  unMergeFinance(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('finance/unMergeFinance'), data,).map(data => { return data; });
  }
  reconciliation(data) {
    return this.http.post<any>(this.appService.getApiEndpoint('bank/bidv/reconciliation'), data,).map(data => { return data; });
  }
  
}
