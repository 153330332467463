<div class="row mb-2 mt-2 justify-content-between">
    <div class="p-0 d-flex justify-content-start align-items-center">


        <mat-form-field appearance="fill" style="width: 200px;" class="mr-3">
            <mat-label>Tổ bộ môn</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.faculty_code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of facultys" [value]="item.code" [disabled]="false">
                    {{item.name}} ({{item.teacherSum}})
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 200px;" class="mr-3">
            <mat-label>Giáo viên</mat-label>
            <mat-select [disabled]="false" [(ngModel)]="filter.code" (selectionChange)="category_change($event)">
                <mat-option [value]="" [disabled]="false">
                    Tất cả
                </mat-option>
                <mat-option *ngFor="let item of teacher_list" [value]="item.code" [disabled]="false">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-button-toggle-group [value]="type_range" #group="matButtonToggleGroup" (change)="onValChange(group.value)" appearance="" name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="day">
                <span class="material-icons mr-1"> event </span> Lịch ngày</mat-button-toggle>
            <mat-button-toggle value="week">
                <span class="material-icons mr-1"> event </span> Lịch tuần</mat-button-toggle>
        </mat-button-toggle-group>


        <button class="ml-4 btn btn-lg btn-labeled btn-labeled-left btn-icon" (click)="confirmDownloadExcel()">
            <span class="btn-label">
                <img class="icon-image-btn" src="./assets/img/icons/excel.png" alt="image" width="20" />
            </span>
            Xuất Excel
        </button>


    </div>

    <div class=" p-0 d-flex justify-content-end align-items-center">
        <mat-form-field class="example-full-width" appearance="fill" style="width: 180px;">
            <mat-label>Ngày báo cáo</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="fromDate" name="fromDateStr" (dateChange)="onDateSelect($event)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
    </div>

</div>

<ul class="nav nav-tabs tabs-line tabs-line-2x nav-fill">

    <li class="nav-item" *ngFor="let item of dateRange; let i = index">
        <a class="nav-link" href="#tab-11-2" data-toggle="tab" [ngClass]="{'active': item.ddmmyyy == this.selectedDateStr, 'text-warning': i >=5 }" (click)="selectDate(item.ddmmyyy)">
            <div>{{item.dayOfWeek}}</div>
            <div style="font-size: large;">{{item.ddmm}}</div>
            <!-- <div style="font-size: large;">{{this.selectedDateStr}}</div> -->
        </a>
    </li>

</ul>




<div>

</div>
<div class="row">
    <table class="table table-head-purple table-hover" id="datatable">
        <thead class="thead-default thead-lg">
            <tr>
                <th style="width: 50px; text-align: center;">TT</th>
                <th style="width: 60px;">Thứ</th>
                <th style="width: 105px;">Ngày</th>
                <th style="width: 110px;">Thời gian</th>
                <th style="width: 115px;">Thời lượng</th>
                <th>Giáo viên</th>
                <th>Lớp</th>
                <th>Môn</th>
                <th>Checkin</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.dataList; let i = index" [ngClass]="{'row_title' : item.checkin_time == undefined}">
                <td class="stt_td" style=" text-align: center; padding: 0.5rem;">
                    <div *ngIf="item.checkin_time != undefined">
                        <!-- <span class="stt_span"><span class="badge badge-default badge-circle">{{i+1}}</span> </span> -->
                        <a class="avatar-img-list" style="width: 30px; border-radius: 50%;  height: 30px;" href="javascript:;" [style.background-image]="appService.showImageTeacher(item.image_url)"></a>
                    </div>
                </td>
                <td>{{item.date_day}}</td>
                <td>{{item.date_item}}</td>
                <td>{{item.checkin_time}}</td>
                <td>{{item.checkin_duration}}</td>
                <td>
                    <div *ngIf="item.checkin_time != undefined">
                        <div style="font-size: 18px;">{{item.teacher_name}}</div>
                        <!-- <div style="font-size: 13px; font-weight: bold;">
                            <span>{{item.teacher_code}}</span>
                            <span class="material-icons font-13 text-success">
                                fiber_manual_record
                                </span>
                            <span>{{item.phone}}</span>
                        </div> -->
                    </div>
                </td>
                <td>{{item.class_name}}</td>
                <td>{{item.subject_name}}</td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>

<!-- <div style="margin: 20px 0px 12px 0px; font-size: 17px; font-weight: bold;">
    <span color="primary">Tổng sỹ số: </span> {{allSchools.student.student_count}}
    <span color="primary">Vắng: </span> 5
    <span color="primary">Tỷ lệ: </span> 97%
</div> -->




<div *ngIf="isLoading" class="spinner-loadClass">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-warning" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<app-nodata *ngIf="!isLoading && this.dataList.length ==0" msg="Không tìm thấy dữ liệu"></app-nodata>